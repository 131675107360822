import React from "react";

function PublicHeader() {
  const signInBtn = () => {
    location.href = "/login";
  };
  return (
    <>
      <div className="mx-sm-5 mx-2">
        <nav className="navbar navbar-dark bg-black navbar-expand-lg rounded px-4">
          <div className="container-fluid">
            <a className="navbar-brand" href="">
              <img
                src="assets/publicImages/w-logo-svg.svg"
                alt=""
                width="200px"
              />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav m-auto mb-2 mb-lg-0">
                {/* <li className="nav-item">
                  <a className="nav-link active" aria-current="page" href="#">
                    Technology
                  </a>
                </li> */}

                <li className="nav-item parent">
                  <a className="nav-link active" aria-current="page" href="#">
                    Services
                  </a>
                  <ul className="children">
                    <li>
                      <a href="/forCompanies">For Companies </a>
                    </li>
                    <li>
                      <a href="/forConsultant">For Consultants </a>
                    </li>
                    <li>
                      <a href="/individuals">For Individuals </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item px-lg-2">
                  <a className="nav-link active" href="#">
                    About us
                  </a>
                </li>
                <li className="nav-item px-lg-2">
                  <a className="nav-link active" href="#">
                    Innovation
                  </a>
                </li>
                <li className="nav-item px-lg-2">
                  <a className="nav-link active" href="/contact-us">
                    Contact Us
                  </a>
                </li>
              </ul>
              <form className="width">
                <button
                  className="btn"
                  type="button"
                  // data-bs-toggle="modal"
                  // data-bs-target="#signUpModal"
                  onClick={() => signInBtn()}
                >
                  Log In
                </button>
              </form>
            </div>
          </div>
        </nav>
      </div>

      {/* <!-- Modal --> */}
      <div
        className="modal fade"
        id="signUpModal"
        tabIndex="-1"
        aria-labelledby="signUpModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content p-5 rounded-5">
            <div className="modal-header border-0 p-0">
              <button
                type="button"
                className="btn-close fs-5"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <h2>Sign In</h2>
              <p>
                Don't have an account? <a href="#">Sign Up</a>
              </p>

              <form action="">
                <div className="form-group mt-5">
                  <label htmlFor="">Email</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your email"
                  />
                </div>
                <div className="form-group mt-3">
                  <label htmlFor="">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Enter your password"
                  />
                </div>
                <div className="mt-3 d-flex align-items-center justify-content-between">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="fieldsetCheck"
                    />
                    <label className="form-check-label" htmlFor="fieldsetCheck">
                      Remember me
                    </label>
                  </div>
                  <a href="#" className="text-black">
                    Forgot Password?
                  </a>
                </div>

                <div className="mt-4 mb-3">
                  <button type="button" className="btn  width-100">
                    Sign In
                  </button>
                </div>
                <div>
                  <button type="button" className="btn btn-google">
                    <img
                      src="assets/publicImages/google-icon.png"
                      alt=""
                      className="me-2"
                    />
                    Sign In With Google
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PublicHeader;
