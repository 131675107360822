import React, { useEffect, useState } from "react";
import GoogleMapsPlaces from "../../../../services/mapsPlaces";
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as turf from "@turf/turf";
import axios from "axios";

// mapboxgl.accessToken =
//   "pk.eyJ1Ijoia3VuZGFnZWV0aGEtMTQiLCJhIjoiY20wZ2Mwc2VzMGs5cTJucjF6dGk4cDc0dyJ9.ASH3-A0NLmHMHwXD9vZw1g"; // Replace with your Mapbox access token

function MapDrawCircle() {
  const [map, setMap] = useState(null);
  const [markerData, setMarkerData] = useState([]);
  const [markers, setMarkers] = useState([]); // Store actual marker instances
  const [drawnShapes, setDrawnShapes] = useState([]);

  const insertMap = async (pointers) => {
    const mapInstance = new mapboxgl.Map({
      container: "map", // ID of your map container
      style: "mapbox://styles/mapbox/streets-v11", // Set your preferred style
      center: [-118.4068, 34.1139], // Longitude, Latitude (Los Angeles)
      zoom: 12,
    });
    setMap(mapInstance);

    // Initialize Mapbox Draw
    const draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true,
      },
      defaultMode: "simple_select",
    });

    mapInstance.on("load", () => {
      mapInstance.addControl(draw);
    });

    const allMarkers = []; // Array to store all markers
    let localdrawnShapes = []; // Store drawn shapes
    const defaultMarkersData = pointers;
    const shapeMarkersMap = new Map(); // Map to track which markers belong to which shapes
    let newMarkers = [];
    let updatedMarkersData = [];
    // Create initial markers
    pointers.forEach((position) => {
      const marker = new mapboxgl.Marker({
        element: createMarkerElement(position),
      })
        .setLngLat([
          position.geometry.location.lng,
          position.geometry.location.lat,
        ])
        .addTo(mapInstance);

      allMarkers.push({ marker, data: position }); // Store marker and its associated data

      const popup = new mapboxgl.Popup({ offset: [0, -15] }).setHTML(
        `<div>
          <h6>${position.name}</h6>
          <p><strong>Vicinity:</strong> ${position.vicinity}</p>
        </div>`
      );

      marker.setPopup(popup);
    });

    setMarkers(allMarkers); // Update state with all the markers
    updateMarkerList(pointers); // Update list with all pointers initially

    // Ensure all markers are visible before drawing
    allMarkers.forEach(({ marker }) => {
      marker.getElement().style.display = "block";
    });

    // Handle draw.create for shape filtering
    mapInstance.on("draw.create", async (event) => {
      const shape = event.features[0];

      localdrawnShapes.push(shape); // Store the drawn shape
      setDrawnShapes((prev) => ({ ...prev, shape }));

      // Extract coordinates from the drawn shape
      const coordinates = shape.geometry.coordinates[0]; // Assuming it's a Polygon

      // Filter markers based on whether they are inside the drawn shape
      const filteredMarkers = allMarkers.filter(({ marker, data }) => {
        const markerPosition = [
          data.geometry.location.lng,
          data.geometry.location.lat,
        ];

        // Use Turf.js to check if the marker is within the drawn polygon
        return turf.booleanPointInPolygon(
          turf.point(markerPosition),
          turf.polygon(shape.geometry.coordinates)
        );
      });

      // Store markers associated with the new shape
      shapeMarkersMap.set(shape.id, filteredMarkers);
      // Hide all markers first
      allMarkers.forEach(({ marker }) => {
        marker.getElement().style.display = "none"; // Hide all markers
      });

      // Keep the drawn shape visible
      draw.add(shape); // Ensure the shape remains on the map after adding new markers

      // Call Google Maps API with shape coordinates
      const newMarkersData = await getDataByShape({
        latitude: shape.geometry.coordinates[0][0][1],
        longitude: shape.geometry.coordinates[0][0][0],
        radius:
          turf.distance(
            turf.point(shape.geometry.coordinates[0][0]),
            turf.point(shape.geometry.coordinates[0][1])
          ) * 1000, // Convert to meters
        type: "shopping", // Adjust as needed
        keyword: "shopping", // Adjust as needed
      });

      // Add new markers to the map (filtered based on the drawn shape)
      addNewMarkersToMap(newMarkersData, mapInstance, shape);
    });

    //   // Handle draw.delete to reset marker visibility
    mapInstance.on("draw.delete", (event) => {
      const deletedFeatures = event.features; // Get deleted shapes
      deletedFeatures.forEach((deletedShape) => {
        newMarkers.forEach(({ newMarker, data }) => {
          const markerPosition = [
            data.geometry.location.lng,
            data.geometry.location.lat,
          ];

          if (deletedShape.geometry.type === "Polygon") {
            if (
              turf.booleanPointInPolygon(
                turf.point(markerPosition),
                turf.polygon(deletedShape.geometry.coordinates)
              )
            ) {
              const markerElement = newMarker.getElement();
              if (markerElement) {
                markerElement.style.display = "none";
              }
            }
          }
        });

        // Filter out the markers from updatedMarkersData that were inside the deleted shape
        updatedMarkersData = updatedMarkersData.map((shapeMarkers) =>
          shapeMarkers.filter(
            (markerData) =>
              !turf.booleanPointInPolygon(
                turf.point([
                  markerData.geometry.location.lng,
                  markerData.geometry.location.lat,
                ]),
                turf.polygon(deletedShape.geometry.coordinates)
              )
          )
        );

        // Remove empty marker sets from the updatedMarkersData
        updatedMarkersData = updatedMarkersData.filter(
          (shapeMarkers) => shapeMarkers.length > 0
        );

        let markersAfterDelete = flattenArray(updatedMarkersData);
        updateMarkerList(markersAfterDelete);
      });

      // Remove deleted shapes from the localdrawnShapes array
      localdrawnShapes = localdrawnShapes.filter(
        (shape) => !deletedFeatures.some((deleted) => deleted.id === shape.id)
      );

      // If no shapes remain, show all markers
      if (localdrawnShapes.length === 0) {
        allMarkers.forEach(({ marker }) => {
          marker.getElement().style.display = "block"; // Ensure all markers are visible
        });
        updateMarkerList(defaultMarkersData);
      }
    });

    // Function to add new markers to the map
    const addNewMarkersToMap = async (
      newMarkersData,
      mapInstance,
      drawnPolygon
    ) => {
      // Filter new markers based on whether they are inside the drawn shape
      const filteredMarkers = newMarkersData.filter((position) => {
        const markerPosition = [
          position.geometry.location.lng,
          position.geometry.location.lat,
        ];

        // Use Turf.js to check if the marker is within the drawn polygon
        return turf.booleanPointInPolygon(
          turf.point(markerPosition),
          turf.polygon(drawnPolygon.geometry.coordinates)
        );
      });

      updatedMarkersData.push(filteredMarkers);

      // console.log(updatedMarkersData, "newMarkersData", filteredMarkers);
      // Call the function to flatten the nested data
      const flattenedData = await flattenArray(updatedMarkersData);
      // console.log(flattenedData, "flattenedData");
      updateMarkerList(flattenedData);
      // Add the filtered markers to the map
      filteredMarkers.forEach((position) => {
        const newMarker = new mapboxgl.Marker({
          element: createMarkerElement(position),
        })
          .setLngLat([
            position.geometry.location.lng,
            position.geometry.location.lat,
          ])
          .addTo(mapInstance);
        newMarkers.push({ newMarker, data: position });

        const popup = new mapboxgl.Popup({ offset: [0, -15] }).setHTML(
          `<div>
              <h6>${position.name}</h6>
              <p><strong>Vicinity:</strong> ${position.vicinity}</p>
          </div>`
        );
        newMarker.setPopup(popup);
        setMarkers(newMarkers); // Update state with all the markers
      });
    };
  };

  // Logic to flatten the array of arrays into a single array of objects
  const flattenArray = (nestedArray) => {
    // Use reduce to combine all sub-arrays into a single array
    return nestedArray.reduce((acc, currentArray) => {
      return acc.concat(currentArray);
    }, []);
  };

  const updateMarkerList = (filteredMarkers) => {
    // console.log(filteredMarkers, "filteredMarkers");
    setMarkerData(filteredMarkers); // Update the marker data
  };

  // Helper function to create a custom marker element
  const createMarkerElement = (position) => {
    const el = document.createElement("div");
    el.className = "custom-marker";
    el.style.backgroundImage = "url(/assets/images/icons/restaurant-new.png)";
    el.style.backgroundSize = "contain";
    el.style.backgroundRepeat = "no-repeat"; // Prevent repeating
    el.style.backgroundPosition = "center"; // Center the background image
    el.style.width = "32px";
    el.style.height = "32px";
    return el;
  };

  useEffect(() => {
    try {
      // axios
      //   .get("/get-map-data")
      //   .then((response) => {
      //     let data = response.data;
      //     // console.log(response, "Mapbox data:", data);
      //   })
      //   .catch((error) => {
      //     console.log("Error fetching Mapbox data:", error);
      //   });

      let data = {
        latitude: 34.1139,
        longitude: -118.4068,
        type: "shopping",
        keyword: "shopping",
        radius: 3000,
      };
      getData(data);
    } catch (error) {}
  }, []);

  const getDataByShape = async (data) => {
    let GetNearPlaces = await GoogleMapsPlaces.GetNearByPlaces(data);
    return GetNearPlaces.results;
  };

  const getData = async (data) => {
    let GetNearPlaces = await GoogleMapsPlaces.GetNearByPlaces(data);
    // setTotalData(GetNearPlaces.results);
    setMarkerData(GetNearPlaces.results);

    await insertMap(GetNearPlaces.results);
  };

  let currentInfoWindow = null; // Variable to store the currently open InfoWindow
  const handleListItemClick = (data) => {
    const markerEntry = markers.find(
      ({ data: markerData }) => markerData.place_id === data.place_id
    );
    const markerData = markerEntry.data;

    if (markerEntry && map) {
      const { marker } = markerEntry;

      // Close the previously open InfoWindow
      if (currentInfoWindow) {
        currentInfoWindow.remove();
      }
      map.flyTo({
        center: [
          markerData.geometry.location.lng,
          markerData.geometry.location.lat,
        ],
        zoom: 13,
      });
      // Create a popup with the information
      const popup = new mapboxgl.Popup()
        .setLngLat([
          markerData.geometry.location.lng,
          markerData.geometry.location.lat,
        ])
        .setHTML(
          ` <h6>${markerData.name}</h6>
          <p>${markerData.vicinity}</p>
          <a href="https://www.google.com/maps/place/?q=place_id:${markerData.place_id}" target="_blank">Get Directions</a>
        `
        )
        .addTo(map);

      // Update the reference to the currently open InfoWindow
      currentInfoWindow = popup;
    } else {
      console.log("Either marker or map is undefined.");
    }
  };

  return (
    <div className="demoView">
      <div>
        <div id="map"></div>
      </div>
      <div className="point-tails">
        {markerData.length > 0 ? (
          markerData.map((data, index) => (
            <div key={index} onClick={() => handleListItemClick(data)}>
              <h3 style={{ cursor: "pointer" }}>{data.name}</h3>
            </div>
          ))
        ) : (
          <h3 className="no-content-msg MiddleText">
            There are no points of interest available. Please select another
            category.
          </h3>
        )}
      </div>
    </div>
  );
}

export default MapDrawCircle;
