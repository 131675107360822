import React, { useEffect, useState } from "react";
import { Select } from "antd";
import jData from "./dataFoursquare.json";
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import AppController from "../controllers/appController";
import { Empty, Popover } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { getGooglePlacesResults } from "./utils";
import GoogleMapsPlaces from "../../services/mapsPlaces";
import OwlCarousel from "react-owl-carousel";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

mapboxgl.accessToken =
  "sk.eyJ1IjoidGVqYWt1bWFyNjIiLCJhIjoiY2x4b2I2M3phMDZ5cDJqcHMxZnVpMjB3bCJ9.uscOlHG0Tdfk3s2LEGX98w";

function NearByPlaces(props) {
  // console.log(props, "props");
  const [state, setState] = useState({
    mapLatitude: props.latitude,
    mapLongitude: props.longitude,
    latitude: props.latitude,
    longitude: props.longitude,
    selectedCategory: "",
    mapRes: [],
    types: [
      {
        name: "Restaurants",
        type: "restaurant",
        faicon: "fa fa-cutlery",
        icon: "../assets/images/restaurant-new.svg",
      },
      {
        name: "Groceries",
        type: "supermarket",
        faicon: "fa fa-shopping-bag",
        icon: "../assets/images/grocery-1.svg",
      },
      {
        name: "Parks",
        type: "park",
        faicon: "fa fa-tree",
        icon: "../assets/images/park-1.svg",
      },
      {
        name: "Shopping",
        type: "shopping_mall",
        faicon: "fa fa-shopping-cart",
        icon: "../assets/images/shopping-3.svg",
      },
      {
        name: "Entertainment",
        type: "night_club",
        faicon: "fa fa-film",
        icon: "../assets/images/entertainment-1.svg",
      },
      {
        name: "Hospitals",
        type: "hospital",
        faicon: "fa fa-h-square",
        icon: "../assets/images/hos-1.svg",
      },
    ],
    type: "restaurant",
    typeName: "Restaurants",
    location: [],
    placeSearched: [],
    placeSearchedMarkers: [],
    markerList: [],
    showlist: false,
    showSearch: false,
    typeicon: 0,
    directionsService: {},
    directionsDisplay: {},
    communityName: "",
    restaurantsArr: [],
    restData: [],
    cityLanding: false,
    Loader: false,
    items: [],
    startPosition: "0",
    categories: [],
    RestaurantsMainArray: [],
    restaurantsList: [],
    typesList: [],
    RestauType: [],
    removeBoundary: false,
    mapText: "",
    activeNumber: -1,
    restaurantsRendered: 0,
    restlist: [],
    restlight: "",
    data: {},
    restlistarray: [],
    NearPlaces: [],
    activeMaker: null,
    activeNumber: -1,
    propertyInfo: false,
    propertyDetails: false,
    isProperty: false,
    propertyData: false,
    propertyName: "",
    propertyAddress: "",
    propertyIndex: "",
    propertyLatitude: "",
    propertyLongitude: "",
    singlePropertyData: {
      latitude: "",
      longitude: "",
      propertyAddress: "",
      propertyName: "",
    },
    singleProperty: false,
  });
  const [map, setMap] = useState(null);
  const [currentPopup, setCurrentPopup] = useState(null);
  const [currentMarker, setCurrentMarker] = useState(null);
  const navigate = useNavigate();
  // console.log(state, "state");
  useEffect(() => {
    async function fetchData() {
      // console.log(props, "useEffectprops");
      setState((prev) => ({
        ...prev,
        // latitude: props.latitude,
        // longitude: props.longitude,
        mapLatitude: props.latitude,
        mapLongitude: props.longitude,
        showlist: false,
        communityName: props.communityName,
        data: props.data,
        Loader: true,
        NearPlaces: "",
        isProperty: false,
        propertyAddress: "",
        propertyName: "",
        propertyIndex: "",
        propertyLatitude: "",
        propertyLongitude: "",
        type: "restaurant",
        typeName: "Restaurants",
      }));
      tab();
      let markers = [];
      let locationdata = {
        latitude: props.latitude,
        longitude: props.longitude,
        singleProperty: state.singleProperty,
      };
      if (props.mapModal) {
        insertMap(markers, state.typeName, locationdata);
      }
    }
    fetchData();
  }, [props]);

  const insertMap = (markersData, typeName, locationdata) => {
    // console.log(markersData, typeName, "insertmap");

    const mapInstance = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/examples/clg45vm7400c501pfubolb0xz",
      center: [locationdata.longitude, locationdata.latitude], // Center map on the property // Initial map center [lng, lat]
      zoom: 13, // Initial map zoom level
    });
    // let markersData = mapState.poi;
    setMap(mapInstance);

    // Convert markersData into GeoJSON format
    const geojson = {
      type: "FeatureCollection",
      features: markersData.map((data) => ({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [data.geometry.location.lng, data.geometry.location.lat],
        },
        properties: {
          name: data.name,
          vicinity: data.vicinity,
          place_id: data.place_id,
          typeName: typeName, // Add typeName to properties
        },
      })),
    };

    setTimeout(() => {
      // alert("hello map resize");
      setState((prev) => ({ ...prev, Loader: false }));
      mapInstance.resize(); // Trigger resize with a slight delay
    }, 100);

    // Load images for all custom markers
    loadCustomMarkerImages(mapInstance);

    // Add the data source with clustering enabled
    mapInstance.on("load", () => {
      mapInstance.addSource("markers", {
        type: "geojson",
        data: geojson,
        cluster: true,
        clusterMaxZoom: 14, // Max zoom level to cluster points
        clusterRadius: 50, // Cluster radius in pixels
      });

      // Add the cluster circles to the map
      mapInstance.addLayer({
        id: "clusters",
        type: "circle",
        source: "markers",
        filter: ["has", "point_count"],
        paint: {
          "circle-color": [
            "step",
            ["get", "point_count"],
            "#000000", // color for small clusters
            100,
            "#000000", // color for medium clusters
            750,
            "#000000", // color for large clusters
          ],
          "circle-radius": [
            "step",
            ["get", "point_count"],
            20, // radius for small clusters
            100,
            30, // radius for medium clusters
            750,
            40, // radius for large clusters
          ],
        },
      });

      // Add the cluster count label
      mapInstance.addLayer({
        id: "cluster-count",
        type: "symbol",
        source: "markers",
        filter: ["has", "point_count"],
        layout: {
          "text-field": "{point_count_abbreviated}",
          "text-font": ["DIN Offc Pro Bold", "Arial Unicode MS Bold"],
          "text-size": 15,
        },
        paint: {
          "text-color": "#FFFFFF", // Set text color to white
        },
      });

      // Add unclustered points as markers with custom icons
      mapInstance.addLayer({
        id: "unclustered-point",
        type: "symbol",
        source: "markers",
        filter: ["!", ["has", "point_count"]],
        layout: {
          "icon-image": ["concat", "custom-marker-", ["get", "typeName"]], // Dynamically get the correct image based on typeName
          "icon-size": 1.0,
        },
      });

      // When a cluster is clicked, zoom into the cluster
      mapInstance.on("click", "clusters", (e) => {
        const features = mapInstance.queryRenderedFeatures(e.point, {
          layers: ["clusters"],
        });
        const clusterId = features[0].properties.cluster_id;
        mapInstance
          .getSource("markers")
          .getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return;
            mapInstance.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom,
            });
          });
      });

      // Show popup for unclustered points
      mapInstance.on("click", "unclustered-point", (e) => {
        const coordinates = e.features[0].geometry.coordinates.slice();
        const { name, vicinity, place_id } = e.features[0].properties;

        const popup = new mapboxgl.Popup({ offset: [0, -15] })
          .setLngLat(coordinates)
          .setHTML(
            `
              <h6>${name}</h6>
              <p>${vicinity}</p>
              <a href="https://www.google.com/maps/place/?q=place_id:${place_id}" target="_blank">Get Directions</a>
            `
          )
          .addTo(mapInstance);
        setCurrentPopup(popup);
        setState((prev) => ({
          ...prev,
          // activeMaker: data,
          activeNumber: place_id,
          latitude: coordinates[1],
          longitude: coordinates[0],
        }));
      });

      // Change the cursor to pointer when hovering over a cluster
      mapInstance.on("mouseenter", "clusters", () => {
        mapInstance.getCanvas().style.cursor = "pointer";
      });

      mapInstance.on("mouseleave", "clusters", () => {
        mapInstance.getCanvas().style.cursor = "";
      });
      // Function to load all custom marker images based on typeName
    });
    function loadCustomMarkerImages(map) {
      const iconMappings = {
        restaurant: "/assets/images/icons/restaurant-new.png",
        supermarket: "/assets/images/icons/grocery-1.png",
        park: "/assets/images/icons/park-1.png",
        shopping_mall: "/assets/images/icons/shopping-3.png",
        night_club: "/assets/images/icons/entertainment-1.png",
        hospital: "/assets/images/icons/hos-1.png",
      };

      Object.keys(iconMappings).forEach((key) => {
        map.loadImage(iconMappings[key], (error, image) => {
          if (error) throw error;
          map.addImage(`custom-marker-${key}`, image);
        });
      });
    }

    // markersData.length > 0 &&
    //   markersData.forEach((data) => {
    //     // Create the marker and set its position
    //     const marker = new mapboxgl.Marker({
    //       element: createCustomMarkerElement(),
    //     })
    //       .setLngLat([data.geometry.location.lng, data.geometry.location.lat])
    //       .addTo(mapInstance);
    //     if (currentPopup) {
    //       currentPopup.remove();
    //     }
    //     // Create and set the popup, then attach it to the marker
    //     const popup = new mapboxgl.Popup({ offset: [0, -15] }).setHTML(`
    //       <h6>${data.name}</h6>
    //       <p>${data.vicinity}</p>
    //       <a href="https://www.google.com/maps/place/?q=place_id:${data.place_id}" target="_blank">Get Directions</a>
    //     `);

    //     // Attach the popup to the marker
    //     marker.setPopup(popup);

    //     // Handle popup open event
    //     popup.on("open", () => {
    //       setCurrentPopup(popup);
    //       setState((prev) => ({
    //         ...prev,
    //         activeMaker: data,
    //         activeNumber: data.place_id,
    //         latitude: data.geometry.location.lat,
    //         longitude: data.geometry.location.lng,
    //       }));
    //     });

    //     // Handle popup close event
    //     popup.on("close", () => {
    //       setState((prev) => ({
    //         ...prev,
    //         activeNumber: null,
    //       }));
    //     });
    //   });

    let dataArray = [];
    if (props.propertyDetails) {
      dataArray = Array.isArray(props.data) ? props.data : [props.data];
    }
    if (props.propertyDetails && Array.isArray(dataArray)) {
      dataArray.forEach((eachdata) => {
        const propertyMarker = new mapboxgl.Marker({
          element: createpropertyMarkerElement(),
        })
          .setLngLat([eachdata.longitude, eachdata.latitude])
          .addTo(mapInstance);

        if (currentPopup) {
          currentPopup.remove();
        }

        const propertypopup = new mapboxgl.Popup({ offset: [0, -15] }).setHTML(`
          <p>${eachdata.address}</p>
        `);
        propertyMarker.setPopup(propertypopup);

        propertypopup.on("open", () => {
          setCurrentPopup(propertypopup);
          setState((prev) => ({ ...prev, propertyInfo: true }));
        });

        propertypopup.on("close", () => {
          setState((prev) => ({ ...prev, propertyInfo: false }));
        });
      });
    } else {
      console.error("props.data is not an array:", dataArray);
    }
    if (currentMarker) {
      currentMarker.remove(); // This will remove the marker from the map
    }
    if (locationdata.singleProperty) {
      if (currentMarker) {
        currentMarker.remove(); // This will remove the marker from the map
      }
      const singlePropertyMarker = new mapboxgl.Marker({
        element: createSinglePropertyElement(locationdata.singlePropertyIndex),
      })
        .setLngLat([locationdata.longitude, locationdata.latitude])
        .addTo(mapInstance);
      if (currentPopup) {
        currentPopup.remove();
      }
      // Create and set the popup, then attach it to the marker
      const popup = new mapboxgl.Popup({ offset: [0, -15] }).setHTML(`
          <p>${
            locationdata.propertyAddress != undefined
              ? locationdata.propertyAddress
              : state.propertyAddress
          }</p>
           `);

      // Attach the popup to the marker
      singlePropertyMarker.setPopup(popup);

      // Handle popup open event
      popup.on("open", () => {
        setCurrentPopup(popup);
      });

      // Handle popup close event
      popup.on("close", () => {});
      // Update the current marker reference
      setCurrentMarker(singlePropertyMarker);
    }
    // });
    // Handle map loading errors
    mapInstance.on("error", (e) => {
      console.error("Error occurred during map loading:", e);
    });
    function createpropertyMarkerElement() {
      const propertyel = document.createElement("div");
      propertyel.className = "marker";
      propertyel.style.backgroundImage = `url(/assets/images/map-Orange_icon.png)`;
      propertyel.style.backgroundSize = "contain";
      propertyel.style.backgroundRepeat = "no-repeat"; // Prevent repeating
      propertyel.style.backgroundPosition = "center"; // Center the background image
      propertyel.style.width = "32px"; // Adjust size as needed
      propertyel.style.height = "32px"; // Adjust size as needed

      return propertyel;
    }
    function createCustomMarkerElement() {
      const el = document.createElement("div");
      el.className = "marker";
      // Determine the image URL based on state.typeName
      let imageUrl;
      switch (typeName) {
        case "restaurant":
          imageUrl = "/assets/images/icons/restaurant-new.svg";
          break;
        case "supermarket":
          imageUrl = "/assets/images/icons/grocery-1.svg";
          break;
        case "park":
          imageUrl = "/assets/images/icons/park-1.svg";
          break;
        case "shopping_mall":
          imageUrl = "/assets/images/icons/shopping-3.svg";
          break;
        case "night_club":
          imageUrl = "/assets/images/icons/entertainment-1.svg";
          break;
        default:
          imageUrl = "/assets/images/icons/hos-1.svg";
          break;
      }
      el.style.backgroundImage = `url(${imageUrl})`;
      el.style.backgroundSize = "contain";
      el.style.backgroundRepeat = "no-repeat"; // Prevent repeating
      el.style.backgroundPosition = "center"; // Center the background image
      el.style.width = "32px"; // Adjust size as needed
      el.style.height = "32px"; // Adjust size as needed
      return el;
    }
    function createSinglePropertyElement(index) {
      const singlepropertyel = document.createElement("div");
      singlepropertyel.className = "marker";
      singlepropertyel.textContent =
        index != undefined ? index : state.propertyIndex;
      // Add CSS styles to the element
      singlepropertyel.style.backgroundImage = `url(/assets/images/mapNew.png)`;
      singlepropertyel.style.backgroundSize = "contain";
      singlepropertyel.style.backgroundRepeat = "no-repeat";
      singlepropertyel.style.backgroundPosition = "center";
      singlepropertyel.style.width = "40px";
      singlepropertyel.style.height = "40px";
      singlepropertyel.style.zIndex = "999";
      singlepropertyel.style.display = "flex";
      singlepropertyel.style.alignItems = "center";
      singlepropertyel.style.justifyContent = "center";
      singlepropertyel.style.color = "#fff"; // Text color
      singlepropertyel.style.fontSize = "14px"; // Text size
      singlepropertyel.style.fontWeight = "bold"; // Text weight
      // singlepropertyel.style.textShadow = "1px 1px 2px rgba(0, 0, 0, 0.7)"; // Text shadow for better readability
      return singlepropertyel;
    }
  };

  const onetype = async (row, type) => {
    // console.log(row, "===", type);
    setState((prev) => ({
      Loader: true,
      ...prev,
      restaurantsArr: [],
      typesList: [],
      restaurantsList: [],
    }));
    if (row.type == "restaurant") {
      let lat = state.mapLatitude;
      let lng = state.mapLongitude;
      let type = row.type;
      loadPlacesWithMarkers(lat, lng, row.type);
      setState((prev) => ({
        ...prev,
        type: row.type,
        showlist: false,
        typeName: row.name,
        typeicon: type,
        Loader: false,
      }));
    } else {
      let lat = state.mapLatitude;
      let lng = state.mapLongitude;
      let type = row.type;
      loadPlacesWithMarkers(lat, lng, row.type);
      setState((prev) => ({
        ...prev,
        type: row.type,
        showlist: false,
        typeName: row.name,
        typeicon: type,
        restlistarray: [],
        Loader: false,
      }));
    }
  };
  const propertyNearBy = async (event, index) => {
    // console.log(event, "event ofpropertyNearBy", index);
    if (!map) {
      console.error("Map instance is not available.");
      return; // Exit if map is not defined
    }
    let singlePropertyData = {
      latitude: event.latitude,
      longitude: event.longitude,
      singleProperty: true,
      propertyAddress: event.address,
      propertyName: event.propertyname,
      singlePropertyIndex: index,
    };
    let GetNearPlaces = await GoogleMapsPlaces.GetNearByPlaces({
      latitude: event.latitude,
      longitude: event.longitude,
      type: state.type,
      keyword: state.type,
      radius: 4000,
    });

    // console.log(GetNearPlaces.results, "GetFourSquare");
    setState((prev) => ({
      ...prev,
      singlePropertyData: {
        latitude: event.latitude,
        longitude: event.longitude,
        propertyAddress: event.address,
        propertyName: event.propertyname,
      },
      NearPlaces: GetNearPlaces.results,
      // latitude: event.latitude,
      // longitude: event.longitude,
      propertyLatitude: event.latitude,
      propertyLongitude: event.longitude,
      mapLatitude: event.latitude,
      mapLongitude: event.longitude,
      isProperty: true,
      propertyAddress: event.address,
      propertyName: event.propertyname,
      propertyIndex: index,
      singleProperty: true,
    }));

    insertMap(GetNearPlaces.results, state.type, singlePropertyData);
  };

  const handleMarkerInfo = (data) => {
    // console.log("handlemarker", data);
    setState((prev) => ({
      ...prev,
      activeMaker: data,
      activeNumber: data.name,
      // latitude: data.geometry.location.lat,
      // longitude: data.geometry.location.lng,
      mapLatitude: data.geometry.location.lat,
      mapLongitude: data.geometry.location.lng,
    }));
  };
  const clearActiveMarker = () => {
    setState((prev) => ({ ...prev, activeMaker: null }));
  };
  const loadPlacesWithMarkers = async (lat, lng, type) => {
    // console.log("clicked the markers function", lat, lng, type);
    let GetNearPlaces = await GoogleMapsPlaces.GetNearByPlaces({
      latitude: lat,
      longitude: lng,
      type: type,
      keyword: type,
      radius: 4000,
    });
    // console.log(GetNearPlaces.results, "GetFourSquare");
    setState((prev) => ({
      ...prev,
      NearPlaces: GetNearPlaces.results,
    }));
    let data = {
      latitude: lat,
      longitude: lng,
      singleProperty: state.singleProperty,
    };
    insertMap(GetNearPlaces.results, type, data);
  };
  const propertyDetailsInfo = () => {
    setState((prev) => ({ ...prev, propertyDetails: true }));
  };
  const clearpropertyDetails = () => {
    setState((prev) => ({ ...prev, propertyDetails: false }));
  };
  const propertyDataInfo = () => {
    setState((prev) => ({ ...prev, propertyData: true }));
  };
  const clearpropertyData = () => {
    setState((prev) => ({ ...prev, propertyData: false }));
  };
  const moreInfoWithDetails = async (row) => {
    await localStorage.setItem("myPropertyDetails", JSON.stringify(row));
    // $("document").ready(function () {
    //   $("#propertymap").modal("hide");
    // });
    $(function () {
      $(document).ready(async function () {
        $("#comparemap").modal("hide");
        $("#propertymap").modal("hide");
        $("body").removeAttr("style");
        $(".modal-backdrop").remove(); // Hide the modal backdrop
      });
    });

    // window.open("/my_property_details", "myWindow");
    navigate("/my_property_details");
  };

  function isNumeric(value) {
    return !isNaN(parseFloat(value)) && isFinite(value);
  }
  const updateCarouselPosition = (object) => {
    if (object.item.index != state.startPosition) {
      setState((prevState) => ({
        ...prevState,
        startPosition: object.item.index,
      }));
    }
  };
  const tab = () => {
    let tabItems =
      parseInt(screen.availWidth) <= 767
        ? 2
        : parseInt(screen.availWidth) <= 991
        ? 2
        : 3;
    setState((prevState) => ({
      ...prevState,
      tabItems: tabItems,
    }));
  };
  const heightView = () => {
    $(".toggleArrow").toggleClass("full");
  };
  return (
    // <div>
    //   <div>
    <div className="city-landing-page newOne">
      {state.Loader == true ? <div className="loading"></div> : null}
      <div className="tabs-view ">
        <ul className="nav nav-pills" role="tablist">
          {state.types.map((row, index) => {
            return (
              <li
                key={index}
                onClick={() => onetype(row, index)}
                className={
                  (state.typeName == "" ? "Restaurants" : state.typeName) ==
                  row.name
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i className={row.faicon}></i>
                <a>{row.name}</a>{" "}
              </li>
            );
          })}
        </ul>
        <div className="row sharlotte-view tab-content ">
          <div className="col-md-8  p-0 rounded-start-4 overflow-hidden">
            <div id="mapview" className="relomap-height">
              <div id="map" style={{ width: "100%", height: "100%" }}></div>
            </div>{" "}
          </div>

          <div className="col-md-4 p-0">
            <div className="map-right-view rounded-end-4">
              <span className="toggleArrow" onClick={() => heightView()}>
                <i className="fa-solid fa-angle-up"></i>
              </span>
              <div className="heightGrid">
                {/* <div className="slider tabSlider mb-3">
                <OwlCarousel
                  className="owl-theme"
                  dots={false}
                  items={state.tabItems}
                  startPosition={state.startPosition}
                  onDragged={(object) => updateCarouselPosition(object)}
                  onTranslated={(object) => updateCarouselPosition(object)}
                >
                  {state.types.map((type, index) => (
                    <div key={index} className="item">
                      <a
                        className={`nav-item${
                          (state.typeName === ""
                            ? " Restaurants"
                            : state.typeName) === type.name
                            ? " active"
                            : ""
                        }`}
                        data-toggle="tab"
                        id={type.name.replace(/[^a-zA-Z]/g, "")}
                        onClick={() => onetype(type, index)}
                      >
                        <i className={type.faicon}></i>
                        {type.name}
                      </a>
                    </div>
                  ))}
                </OwlCarousel>
              </div> */}
                {props.propertyDetails && (
                  <div className="propertyresulttext">
                    {props.myProp.length > 0 ? (
                      props.myProp.map((row, index) => {
                        return (
                          <div className="shResultspageimages" key={index}>
                            <span className="ScoreLabel sharlotte">
                              <Popover
                                placement="leftTop"
                                content={
                                  <div>
                                    {row.propertyrankitems.propertyrankingitems.map(
                                      (Pitem) => {
                                        return (
                                          <p
                                            key={
                                              "sharlotte" +
                                              index +
                                              Pitem.prioritylistitem
                                            }
                                          >
                                            {Pitem.prioritylistitem ==
                                            "Must Haves" ? (
                                              <Popover
                                                placement="leftTop"
                                                content={
                                                  <div>
                                                    {Pitem.matcheditems.map(
                                                      (matcheditem) => {
                                                        return (
                                                          matcheditem.itemname !=
                                                            "" && (
                                                            <p
                                                              key={
                                                                "sharlottepps" +
                                                                index +
                                                                matcheditem.itemname
                                                              }
                                                            >
                                                              {
                                                                matcheditem.itemname
                                                              }
                                                              {matcheditem.ismatched ==
                                                                true && (
                                                                <i className="fa fa-check"></i>
                                                              )}
                                                              {matcheditem.ismatched ==
                                                                false && (
                                                                <i className="fa fa-times"></i>
                                                              )}
                                                            </p>
                                                          )
                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                }
                                                title="Matched Must Haves"
                                                trigger="hover"
                                              >
                                                {Pitem.prioritylistitem}{" "}
                                                <i
                                                  className="fa fa-info-circle"
                                                  style={{
                                                    color: "#000000",
                                                  }}
                                                ></i>
                                              </Popover>
                                            ) : (
                                              Pitem.prioritylistitem
                                            )}
                                            {Pitem.ismatched == true &&
                                              Pitem.isallmatched == false && (
                                                <i className="fa fa-check yellow_clr"></i>
                                              )}
                                            {Pitem.ismatched == true &&
                                              Pitem.isallmatched == true && (
                                                <i className="fa fa-check"></i>
                                              )}
                                            {Pitem.ismatched == true &&
                                              Pitem.isallmatched == null && (
                                                <i className="fa fa-check"></i>
                                              )}
                                            {Pitem.ismatched == false && (
                                              <i className="fa fa-times"></i>
                                            )}
                                          </p>
                                        );
                                      }
                                    )}
                                  </div>
                                }
                                title="Matched Priorities"
                                trigger="hover"
                              >
                                {Math.trunc(row.propertyrankitems.matchpercent)}
                                %
                              </Popover>
                            </span>
                            <div
                              className={
                                state.propertyIndex == index + 1
                                  ? "shr_textmain active "
                                  : "shr_textmain "
                              }
                            >
                              {/* <div
                                className={
                                  state.propertyIndex == index + 1
                                    ? "selectnumber "
                                    : "selectnumber "
                                }
                                onClick={() => propertyNearBy(row, index + 1)}
                              >
                                {index + 1}
                              </div> */}
                              <div
                                className="shr_text"
                                // onClick={() => props.mapIndexChange(row, index)}
                                onClick={() => propertyNearBy(row, index + 1)}
                                style={{ cursor: "pointer" }}
                              >
                                <a
                                  href="javascript:void(0)"
                                  onClick={() => moreInfoWithDetails(row)}
                                >
                                  {row.propertyname}{" "}
                                  <i className="fa-regular fa-arrow-up-right"></i>
                                </a>
                                <span> {row.address} </span>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <span className="MapEmptyText">
                        <Link to="/myProperty">
                          Please add rentals to see here
                        </Link>
                      </span>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    //   </div>
    // </div>
  );
}

export default NearByPlaces;
