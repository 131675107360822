import React, { useEffect, useState, useRef } from "react";
import LoginStore from "../../../services/thirdPartyService";
import apiServices from "../../../services/apiServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Menu, Dropdown } from "antd";
import { useLocation } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import { options } from "@fullcalendar/core/preact";
import SessionTimeout from "../../pages/sessionTimeout";

// import "./header.css";
const { SubMenu } = Menu;

const Header = ({ user }) => {
  const [state, setState] = useState({
    loggedIn: 2,
    istemporary: "",
    subscriptioncode: "",
    getSessionData: "",
    diversityPreferenceAnswer: "",
  });
  // const [menuShow, setMenuShow] = useState("collapse navbar-collapse");
  // const [showStatus, setShowStatus] = useState(false);
  // const elementRef = useRef(null);
  useEffect(() => {
    const fetch = async () => {
      let getSessionData = await LoginStore.getLoginInfo({});
      // console.log(getSessionData, "getSessionData");
      if (Object.keys(getSessionData).length > 0) {
        if (getSessionData.type == "TRANSFEREE") {
          getpreferencelist(getSessionData);
          await setState((prev) => ({
            ...prev,
            loggedIn: 1,
            istemporary: getSessionData.istemporary,
            subscriptioncode: getSessionData.subscriptioncode,
            getSessionData: getSessionData,
          }));
        } else if (getSessionData.type == "SUPPLIER") {
          await setState((prev) => ({ ...prev, loggedIn: 2 }));
        } else if (getSessionData.type == "CONSULTANT") {
          await setState((prev) => ({ ...prev, loggedIn: 3 }));
        } else {
          logoutFunc();
        }
      } else {
        await setState((prev) => ({ ...prev, loggedIn: 0 }));
      }
    };
    fetch();
  }, []);
  const getpreferencelist = async (getSessionData) => {
    let userPreferanceObject = {
      transfereeuniqeuid: getSessionData.userUniqueId,
      istemporary: getSessionData.istemporary,
    };

    let userSavedPreferances = await apiServices.GetTransfereePreferences(
      userPreferanceObject
    );
    if (userSavedPreferances.error == 0) {
      let userAnswerPreferanceObject = userSavedPreferances.message.preferences;
      // console.log(userAnswerPreferanceObject, "userSavedPreferances");

      const diversityQuestion = userAnswerPreferanceObject.find(
        (item) => item.question === "Include Diversity & Inclusion information"
      );

      const preferenceAnswer = diversityQuestion
        ? diversityQuestion.preferencesanswer
        : null;
      setState((prev) => ({
        ...prev,
        diversityPreferenceAnswer: preferenceAnswer,
      }));
    }
  };
  // const openNav = () => {
  //   console.log("hello", showStatus);

  //   if (showStatus == false) {
  //     setMenuShow("collapse navbar-collapse show");
  //     setShowStatus(true);
  //   } else if (showStatus == true) {
  //     $("#collapsibleNavbar").removeClass("show");
  //     setMenuShow("collapse navbar-collapse");
  //     setShowStatus(false);
  //   }

  //   // navbarCollapse.classList.toggle("show");
  // };
  const closeNav = () => {
    $("#collapsibleNavbar").removeClass("show");
  };
  return (
    <div
      className={
        localStorage.getItem("LoggedInMobile") == "true" ? "mAppView" : ""
      }
    >
      <div className=" fixed-top ">
        <nav
          className={
            state.loggedIn == 0
              ? "navbar navbar-expand-lg dssolonavbar beforelogin"
              : "navbar navbar-expand-lg dssolonavbar"
          }
        >
          {state.loggedIn == 1 &&
            localStorage.getItem("LoggedInMobile") !== "true" && (
              <a className="navbar-brand" href="/dsLanding">
                <span className="main-logo-text">
                  <img src="/assets/newImages/relo-b&W.png" width="80%" />
                </span>{" "}
              </a>
            )}
          {state.loggedIn == 2 &&
            localStorage.getItem("LoggedInMobile") !== "true" && (
              <a className="navbar-brand" href="/Dashboard">
                <span className="main-logo-text">
                  <img src="/assets/newImages/relo-b&W.png" width="80%" />
                </span>{" "}
              </a>
            )}
          {state.loggedIn == 3 &&
            localStorage.getItem("LoggedInMobile") !== "true" && (
              <a className="navbar-brand" href="/ConsultantDashboard">
                <span className="main-logo-text">
                  <img src="/assets/newImages/relo-b&W.png" width="80%" />
                </span>{" "}
              </a>
            )}
          {state.loggedIn == 0 &&
            localStorage.getItem("LoggedInMobile") !== "true" && (
              <a className="navbar-brand" href="/ConsultantDashboard">
                <span className="main-logo-text">
                  <img src="/assets/newImages/relo-b&W.png" width="80%" />
                </span>{" "}
              </a>
            )}
          {localStorage.getItem("LoggedInMobile") !== "true" && (
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse" // Use data-bs-toggle for Bootstrap 5
              data-bs-target="#collapsibleNavbar" // Use data-bs-target for Bootstrap 5
              // onClick={() => openNav()}
              id="toggleBtn"
            >
              <span className="navbar-toggler-icon">
                <FontAwesomeIcon icon={faBars} />
              </span>
            </button>
          )}

          {/* {console.log(state.loggedIn, "7676")} */}
          {state.loggedIn === 1 ? (
            <div className="collapse navbar-collapse" id="collapsibleNavbar">
              <TransfereeHeaderMenu state={state} closeNav={closeNav} />
            </div>
          ) : state.loggedIn === 2 ? (
            <div className="collapse navbar-collapse" id="collapsibleNavbar">
              <SupplierHeaderMenu />
            </div>
          ) : state.loggedIn === 3 ? (
            <div className="collapse navbar-collapse" id="collapsibleNavbar">
              <ConsultantHeaderMenu closeNav={closeNav} />
            </div>
          ) : (
            <NoUserHeaderMenu />
          )}
          {/* <div className="collapse navbar-collapse " id="collapsibleNavbar">
            {headerBasedOnUser(user)}
          </div> */}
        </nav>
      </div>

      <div className="nav_bottom_height"></div>
    </div>
  );
};

export default Header;

function logoutFunc(e) {
  LoginStore.sessionDestory();
  window.location.href = "/";
}

function headerBasedOnUser(user) {
  if (user === "supplier") {
    return <SupplierHeaderMenu />;
  } else if (user === "consultant") {
    return <ConsultantHeaderMenu />;
  } else if (user === "transferee") {
    return <TransfereeHeaderMenu />;
  } else if (user === null) {
    return <NoUserHeaderMenu />;
  }
}
const SupplierHeaderMenu = () => {
  const menu = [
    {
      key: "1",
      label: (
        <a href="javascript:void(0)" onClick={logoutFunc}>
          <i className="fa fa-sign-out"></i> Logout
        </a>
      ),
    },
  ];

  const reportsMenu = [
    {
      key: "1",
      label: (
        <Link className="dropdown-item" to="/reports">
          SignUp Report
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link className="dropdown-item" to="/appointementreports">
          Appointment Report
        </Link>
      ),
    },
    {
      key: "3",
      label: (
        <Link className="dropdown-item" to="/transfereereports">
          Transferee Reports
        </Link>
      ),
    },
    {
      key: "4",
      label: (
        <Link className="dropdown-item" to="/billingreports">
          Billing Report
        </Link>
      ),
    },
  ];

  return (
    <>
      {/* <SessionTimeout onLogout={logoutFunc} /> */}
      <ul className="navbar-nav m-auto">
        <li className="nav-item">
          <Link
            to="/Dashboard"
            className={
              location.pathname == "/Dashboard" ? "nav-link active" : "nav-link"
            }
          >
            Dashboard
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="/ConsultantList"
            className={
              location.pathname == "/ConsultantList"
                ? "nav-link active"
                : "nav-link"
            }
          >
            Consultant List
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="/SupplierTransfereeList"
            className={
              location.pathname == "/SupplierTransfereeList"
                ? "nav-link active"
                : "nav-link"
            }
          >
            Transferee List
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="/ClientProfileList"
            className={
              location.pathname == "/ClientProfileList"
                ? "nav-link active"
                : "nav-link"
            }
          >
            Client profile
          </Link>
        </li>

        <li className="dropdown nav-item nav-item-reports">
          <Dropdown menu={{ items: reportsMenu }} trigger={["click"]}>
            <a
              onClick={(e) => e.preventDefault()}
              className={
                location.pathname == "/reports" ||
                location.pathname == "/appointementreports" ||
                location.pathname == "/transfereereports" ||
                location.pathname == "/billingreports"
                  ? "ant-dropdown-link nav-link active dropdown-toggle"
                  : "ant-dropdown-link nav-link dropdown-toggle"
              }
            >
              Reports <DownOutlined />
            </a>
          </Dropdown>
        </li>
      </ul>

      <ul className="navbar-nav nav-item icon-uls">
        <Dropdown menu={{ items: menu }} trigger={["click"]}>
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            <i className="fa-regular fa-circle-user"></i>
            <DownOutlined />
          </a>
        </Dropdown>
      </ul>
    </>
  );
};

const ConsultantHeaderMenu = ({ closeNav }) => {
  const menu = [
    {
      key: "1",
      label: (
        <Link to="/ConsultantProfile" onClick={() => closeNav()}>
          <i className="fa fa-user-circle"></i> Profile
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <a href="javascript:void(0)" onClick={logoutFunc}>
          <i className="fa fa-sign-out"></i> Logout
        </a>
      ),
    },
  ];

  const reportsMenu = [
    {
      key: "1",
      label: (
        <Link
          className="dropdown-item"
          to="/consultantreports"
          onClick={() => closeNav()}
        >
          SignUp Report
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link
          className="dropdown-item"
          to="/consultantappointementreports"
          onClick={() => closeNav()}
        >
          Appointment Report
        </Link>
      ),
    },
    {
      key: "3",
      label: (
        <Link
          className="dropdown-item"
          to="/consultanttransfereereports"
          onClick={() => closeNav()}
        >
          Transferee Reports
        </Link>
      ),
    },
  ];

  return (
    <>
      {/* <SessionTimeout onLogout={logoutFunc} /> */}
      <div className="d-flex align-items-center">
        <ul className="navbar-nav m-auto">
          <li className="nav-item">
            <Link
              to="/ConsultantDashboard"
              onClick={() => closeNav()}
              className={
                location.pathname == "/ConsultantDashboard"
                  ? "nav-link active"
                  : "nav-link"
              }
            >
              Dashboard
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/ConsultantSchedules"
              //onClick={() => openNav()}
              onClick={() => closeNav()}
              className={
                location.pathname == "/ConsultantSchedules"
                  ? "nav-link active"
                  : "nav-link"
              }
            >
              Schedules
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/Consultant"
              //onClick={() => openNav()}
              onClick={() => closeNav()}
              className={
                location.pathname == "/Consultant"
                  ? "nav-link active"
                  : "nav-link"
              }
            >
              Chat
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/ConsultantTransfereeList"
              //onClick={() => openNav()}
              onClick={() => closeNav()}
              className={
                location.pathname == "/ConsultantTransfereeList"
                  ? "nav-link active"
                  : "nav-link"
              }
            >
              Transferees List
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/Properties"
              //onClick={() => openNav()}
              onClick={() => closeNav()}
              className={
                location.pathname == "/Properties"
                  ? "nav-link active"
                  : "nav-link"
              }
            >
              Properties
            </Link>
          </li>

          <li className="dropdown nav-item nav-item-reports">
            <Dropdown menu={{ items: reportsMenu }} trigger={["click"]}>
              <a
                // className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
                className={
                  location.pathname == "/consultantreports" ||
                  location.pathname == "/consultantappointementreports" ||
                  location.pathname == "/consultanttransfereereports"
                    ? " ant-dropdown-link nav-link active dropdown-toggle"
                    : " ant-dropdown-link nav-link dropdown-toggle"
                }
              >
                Reports <DownOutlined />
              </a>
            </Dropdown>
          </li>

          <ul className="navbar-nav nav-item icon-uls m-0 align-items-center">
            <Dropdown menu={{ items: menu }} trigger={["click"]}>
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <i className="fa-regular fa-circle-user"></i>
                <DownOutlined />
              </a>
            </Dropdown>
          </ul>
        </ul>
      </div>
    </>
  );
};

const TransfereeHeaderMenu = ({ state, closeNav }) => {
  const menu = [
    {
      key: "1",
      label: (
        <Link
          to={{
            pathname: "/singleProfile",
            state: { sessionData: state.getSessionData }, // Pass state.getSessionData here
          }}
          onClick={() => closeNav()}
        >
          <i className="fa fa-user-circle"></i> Profile
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <a href="javascript:void(0)" onClick={logoutFunc}>
          <i className="fa fa-sign-out"></i> Logout
        </a>
      ),
    },
  ];
  return (
    <>
      {/* <SessionTimeout onLogout={logoutFunc} /> */}
      <div className="d-flex align-items-center">
        <ul className="navbar-nav m-auto">
          <li className="nav-item">
            <Link
              to="/getStarted"
              //onClick={() => openNav()}

              onClick={() => closeNav()}
              className={
                location.pathname == "/getStarted"
                  ? "nav-link active"
                  : "nav-link"
              }
            >
              My Preferences
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/citylist"
              //onClick={() => openNav()}
              onClick={() => closeNav()}
              className={
                location.pathname == "/citylist"
                  ? "nav-link active"
                  : "nav-link"
              }
            >
              Explore
            </Link>
          </li>
          {state.istemporary != true ? (
            <li className="nav-item">
              <Link
                to="/myProperty"
                //onClick={() => openNav()}
                onClick={() => closeNav()}
                className={
                  location.pathname == "/myProperty"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                Home Finding
              </Link>
            </li>
          ) : (
            ""
          )}

          <li className="nav-item">
            <Link
              to="/checklist"
              //onClick={() => openNav()}
              onClick={() => closeNav()}
              className={
                location.pathname == "/checklist"
                  ? "nav-link active"
                  : "nav-link"
              }
            >
              Check List
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/resources"
              //onClick={() => openNav()}
              onClick={() => closeNav()}
              className={
                location.pathname == "/resources"
                  ? "nav-link active"
                  : "nav-link"
              }
            >
              Resources
            </Link>
          </li>
          {state.diversityPreferenceAnswer === "true" && (
            <li className="nav-item">
              <Link
                to="/die-data"
                //onClick={() => openNav()}
                onClick={() => closeNav()}
                className={
                  location.pathname == "/die-data"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                Diversity & Inclusion
              </Link>
            </li>
          )}

          {state.subscriptioncode == "Commander" ? (
            <li className="nav-item">
              <Link
                to="/dsProfile"
                // onClick={() => openNav()}
                onClick={() => closeNav()}
                className={
                  location.pathname == "/dsProfile"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                Commander
              </Link>
            </li>
          ) : (
            ""
          )}
        </ul>
        <ul className="navbar-nav icon-uls d-flex align-items-center m-0">
          {state.istemporary != true ? (
            <li>
              <Link
                to="/dsLanding"
                className="homeicon-in-navbar"
                onClick={() => closeNav()}
              >
                <i className="fa-regular fa-home"></i>
              </Link>
            </li>
          ) : (
            ""
          )}

          <li className="nav-item dropdown"></li>
          <Dropdown menu={{ items: menu }} trigger={["click"]}>
            <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              <i className="fa-regular fa-circle-user"></i>
              <DownOutlined />
            </a>
          </Dropdown>
        </ul>
      </div>
    </>
  );
};

export const NoUserHeaderMenu = () => {
  const location = useLocation();
  return (
    <>
      <div className="collapse navbar-collapse" id="collapsibleNavbar">
        <ul className="navbar-nav m-auto ">
          <li className="nav-item">
            <Link
              className={
                location.pathname == "/home" || location.pathname == "/"
                  ? "nav-link active"
                  : "nav-link"
              }
              to="/home"
            >
              How it works?
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={
                location.pathname == "/pricing" ? "nav-link active" : "nav-link"
              }
              to="/pricing"
            >
              Pricing
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={
                location.pathname == "/contactus"
                  ? "nav-link active"
                  : "nav-link"
              }
              to="/contactus"
            >
              Contact us
            </Link>
          </li>
        </ul>
        <ul className="navbar-nav ml-auto login-ul">
          <li className="nav-item">
            <Link className="nav-link" to="/login">
              Login
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};
