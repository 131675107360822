// import "../cityLanding/citysLanding.css";
import ApiServices from "../../../../services/apiServices";
import LoginStore from "../../../../services/thirdPartyService";
import AppController from "../../../controllers/appController";
import { Spin, Select, Radio, Tooltip, Popover, Button } from "antd";
import * as $ from "jquery";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css"; // Bootstrap CSS
import "bootstrap/dist/js/bootstrap.bundle.min";
import AddEditModal from "./modalPopUp/addEditModal";
import InactiveModal from "./modalPopUp/inactiveModal";
import CompareModal from "./modalPopUp/compareModal";
import { useState, useEffect } from "react";
import MyPreferencesModal from "./modalPopUp/myPreferencesModal";
import _ from "lodash";
import moment from "moment";
import SaProperties from "../dsProfile/saProperties";
import Footer from "../../../common/footer/Footer";
import { confirmAlert } from "react-confirm-alert";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Maplist from "../maplist/maplist";
import CompareMapList from "../compareMapList/maplist";

const MyProperties = (props) => {
  var _isMounted = false;
  const [state, setState] = useState({
    button: "active",
    cityLoader: false,
    latitude: "",
    longitude: "",
    nearByCitiesWithStates: [],
    nearByCities: [],
    workLocationAddress: "",
    updatedDate: "",
    propertyName: "",
    propertyLink: "",
    address: "",
    propertyNotes: "",
    loader: false,
    latitude: "",
    longitude: "",
    isPropertyError: false,
    userUniqueId: "",
    errorMessage: "",
    propertySuccess: false,
    myPropertiesList: "",
    propertyLoader: true,
    state: "",
    city: "",
    country: "",
    EditMoveDate: null,
    uploadImages: [],
    uploadLoader: false,
    availableTillDate: null,
    modalpopupInfo: {},
    showModal: false,
    ShowSuccess: false,
    monthlyrent: "",
    hometype: "",
    amenities: null,
    isDelete: false,
    propertyanalyticsuniqueId: "",
    compareProperties: [],
    finalObjectCampare: [],
    tabItems: "2",
    commuteTime: 0,
    answersPrefer: [],
    PrioritiesStatus: null,
    preferenecesBudget: 0,
    messageDistance: "",
    checkExceed: false,
    anywayProceed: false,
    showProceedBtn: false,
    bathrooms: "",
    myPropertiesListInactive: [],
    unitnumber: "",
    flooring: "",
    sqfeet: "",
    virtual360tour: "",
    isinterested: true,
    workAddress: "",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [mapModal, setMapmodal] = useState(false);
  const [compareMap, setCompareMap] = useState(false);
  const [inactModal, setInactModal] = useState(false);

  const navigate = useNavigate();
  const openModal = (e) => {
    //setModalIsOpen(true);
    $(function () {
      $("document").ready(async function () {
        $("#preference-modal").modal("show");
      });
    });
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  const closeModall = () => {
    setIsModalOpen(false);
  };
  const closeMap = () => {
    // setMapmodal(false);
  };
  const closeInact = () => {
    setInactModal(false);
  };

  const onClickEditProperty = async (row) => {
    // console.log(row, "row data of the edited property");
    //setIsModalOpen(true);
    $(function () {
      $("document").ready(async function () {
        await setState((prevState) => ({
          ...prevState,
          checkExceed: false,
          uploadLoader: false,
          loader: false,
          anywayProceed: false,
          showProceedBtn: false,
          transfereeuniqueId: state.userUniqueId,
          apiId: row.apiId,
          propertyanalyticsuniqueId: row.propertyanalyticsuniqueId,
          source: "my_property",
          city: row.city,
          isDelete: row.isactive,
          propertyuniqueId: row.propertyuniqueId,
          propertyName: row.propertyname,
          address: row.address,
          state: row.state,
          latitude: row.latitude,
          longitude: row.longitude,
          propertyLink: row.propertylink,
          propertyNotes: row.notes,
          bathrooms: row.bathrooms,
          isIncluded: false,
          monthlyrent: row.monthlyrent,
          amenities: row.amenities,
          hometype: row.hometype,
          bedrooms: row.bedrooms,
          isDelete: false,
          unitnumber: row.unitnumber,
          isinterested: row.isinterested,
          flooring: row.flooring,
          sqfeet: row.sqfeet,
          virtual360tour: row.virtual360tour,
          uploadImages:
            row.property_image == null || row.property_image == ""
              ? []
              : prepareListFromImageUrlString(row.property_image),
          EditMoveDate: new Date(row.available_till_date),
          availableTillDate: moment(row.available_till_date).format(
            "YYYY/MM/DD"
          ),
        }));

        await setState((prevState) => ({
          ...prevState,
          propertySuccess: false,
          isPropertyError: false,
          ShowSuccess: false,
        }));
        $("#add-property-modal").modal("show");
      });
    });
  };
  function prepareListFromImageUrlString(str) {
    if (!str) {
      return [];
    }
    let images = str.split(",");
    let list = [];
    if (images.length > 0) {
      images.forEach((image) => {
        list.push({
          Location: image,
          key: image.replace(
            /^https:\/\/relonavigator\.s3\.us-west-1\.amazonaws\.com\//,
            ""
          ),
        });
      });
      return list;
    }
    return [];
  }
  const removeimage = async (value, id) => {
    let list = Object.assign([], state.uploadImages);

    let arr = list.filter((item) => item !== value);

    await setState((prevState) => ({ ...prevState, uploadImages: arr }));
  };
  const onClickAddProperty = async () => {
    //setIsModalOpen(true);
    $(function () {
      $("document").ready(async function () {
        await setState((prevState) => ({
          ...prevState,
          checkExceed: false,
          uploadLoader: false,
          loader: false,
          anywayProceed: false,
          transfereeuniqueId: "",
          apiId: "",
          propertyanalyticsuniqueId: "",
          source: "my_property",
          city: "",
          isDelete: false,
          propertyName: "",
          address: "",
          state: "",
          latitude: "",
          longitude: "",
          propertylink: "",
          propertyLink: "",
          notes: "",
          uploadImages: [],
          hometype: "",
          amenities: null,
          monthlyrent: "",
          virtual360tour: "",
          isIncluded: false,
          property_image: "",
          available_till_date: "",
          bathrooms: "",
          bedrooms: "",
          issharedliving: false,
          showProceedBtn: false,
          EditMoveDate: null,
          propertyNotes: "",
          unitnumber: "",
          flooring: "",
          sqfeet: "",
          isinterested: true,
          availableTillDate: moment().format("YYYY/MM/DD"),
        }));
        await setState((prevState) => ({
          ...prevState,
          propertySuccess: false,
          isPropertyError: false,
          ShowSuccess: false,
        }));
        $("#add-property-modal").modal("show");
      });
    });
  };
  const setLocation = async (e) => {
    let Id = e.target.name;
    if (e.target.value.trim() == null || e.target.value.trim() == "") {
      $("#" + Id).addClass("is-invalid");
    } else {
      $("#" + Id).removeClass("is-invalid");
    }
    let LocationObje = {};
    LocationObje[Id] = e.target.value;
    setState((prev) => ({ ...prev, LocationObje }));
    var places = new google.maps.places.Autocomplete(
      document.getElementById(Id),
      { types: ["address"] }
    );
    places.setComponentRestrictions({ country: ["us"] });
    google.maps.event.addListener(places, "place_changed", async () => {
      var place = places.getPlace();
      var placeDetails = {
        locality: "long_name",
        administrative_area_level_1: "short_name",
        country: "long_name",
      };
      for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0];
        if (placeDetails[addressType]) {
          var val = place.address_components[i][placeDetails[addressType]];
          placeDetails[addressType] = val;
        }
      }
      let relocatedAddress = place.formatted_address;
      setState((prevState) => ({
        ...prevState,
        address: relocatedAddress,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        state: placeDetails.administrative_area_level_1,
        city: placeDetails.locality,
      }));
    });
  };
  const inputHandler = async (event) => {
    //event.preventDefault();
    let inputVal = event.target.value;
    let inputObject = {};
    let inputName = event.target.name;
    inputObject[inputName] = inputVal;

    await setState((prev) => ({ ...prev, inputObject }));
    if (
      (inputName == "monthlyrent" || inputName == "flooring") &&
      inputVal < 1 &&
      inputVal != ""
    ) {
      inputObject[inputName] = "";
      $("#" + inputName).addClass("is-invalid");
    } else if (inputVal.trim() == null || inputVal.trim() == "") {
      if (inputName == "flooring" || inputName == "sqfeet") {
        $("#" + inputName).removeClass("is-invalid");
      } else {
        $("#" + inputName).addClass("is-invalid");
      }
    } else {
      $("#" + inputName).removeClass("is-invalid");
    }
    if (inputName != "" && inputVal != "") {
      setState((prevState) => ({
        ...prevState,
        [inputName]: inputVal,
      }));
    }
    if (inputVal.trim() == null || inputVal.trim() == "") {
      $("#" + inputName).addClass("is-invalid");
    } else {
      $("#" + inputName).removeClass("is-invalid");
    }
    event.preventDefault();
  };
  const goToMap = async () => {
    await localStorage.setItem(
      "compareProperties",
      JSON.stringify(state.finalObjectCampare)
    );
    // console.log(state.finalObjectCampare, "cv");
    // $("document").ready(function () {
    //   $("#compare-community-Modal").modal("hide");
    // });
    setCompareMap(true);
    $("document").ready(function () {
      $("#comparemap").modal("show");
    });
    // window.open("/CompareMapList", "myWindow");
  };
  const Close = () => {
    $(function () {
      $("document").ready(async function () {
        $("#comparemap").modal("hide");
      });
    });
    setState((prev) => ({ ...prev }));
    setCompareMap(false);
  };

  const updateDate = async (date) => {
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    var day = date.getDate();
    var monthIndex = date.getMonth();
    var year = date.getFullYear();
    if (_isMounted) {
      await setState((prevState) => ({
        ...prevState,
        updatedDate: day + " " + monthNames[monthIndex] + " " + year,
      }));
    }
    return day + " " + monthNames[monthIndex] + " " + year;
  };
  const getMyProperties = async (PrioritiesStatus = null) => {
    let getSessionData = await LoginStore.getLoginInfo({});
    // if (_isMounted) {
    await setState((prevState) => ({
      ...prevState,
      propertyLoader: true,
      PrioritiesStatus: PrioritiesStatus,
    }));
    // }
    let GetTransfereePropertiesObject = {
      transfereeuniqueId: getSessionData.userUniqueId,
    };
    let GetTransfereeProperties = await ApiServices.GetTransfereeProperties(
      GetTransfereePropertiesObject
    );
    if (GetTransfereeProperties.error == 0) {
      let myPropertiesList = _.filter(
        GetTransfereeProperties.message.transfereepropertyList,
        function (element) {
          return (
            element.source == "my_property" &&
            element.isinterested == true &&
            element.apiId != "" &&
            element.isactive == true
          );
        }
      );
      let myPropertiesListInactive = _.filter(
        GetTransfereeProperties.message.transfereepropertyList,
        function (element) {
          return (
            element.source == "my_property" &&
            element.isinterested == false &&
            element.apiId != ""
          );
        }
      );
      // console.log(myPropertiesList, "myPropertiesList");
      // if (_isMounted) {
      await setState((prevState) => ({
        ...prevState,
        myPropertiesListInactive: myPropertiesListInactive,
        myPropertiesList: myPropertiesList,
        propertyLoader: false,
        PrioritiesStatus: null,
      }));
      // }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetch = async () => {
      getAssignmentProperties();
      _isMounted = true;
      updateDate(new Date());
      let getSessionData = await LoginStore.getLoginInfo({});
      await AppController.logData(
        "Home Finding",
        "Home Finding",
        location.pathname,
        getSessionData.userUniqueId,
        ""
      );
      if (_isMounted) {
        setState((prevState) => ({
          ...prevState,
          userUniqueId: getSessionData.userUniqueId,
          propertyLoader: true,
          issharedliving: getSessionData.issharedliving,
        }));
      }
      let userObject = {
        transfereeuniqeuid: getSessionData.userUniqueId,
        istemporary: getSessionData.istemporary,
      };
      let getQuestionList = await ApiServices.GetPreferencesList(userObject);
      let userPreferanceObject = {
        transfereeuniqeuid: getSessionData.userUniqueId,
        istemporary: getSessionData.istemporary,
      };
      let userSavedPreferances = await ApiServices.GetTransfereePreferences(
        userPreferanceObject
      );
      if (userSavedPreferances.error == 0) {
        let userAnswerPreferanceObject =
          userSavedPreferances.message.preferences;
        if (userAnswerPreferanceObject != undefined) {
          let answersPrefer = {};
          _.forEach(userAnswerPreferanceObject, function (answerObject) {
            answersPrefer[answerObject.preferencesmasteruniqueid] =
              answerObject;
          });
          await setState((prevState) => ({
            ...prevState,
            answersPrefer: answersPrefer,
          }));
          // console.log(state.answersPrefer, "answe");
          workData(answersPrefer, getQuestionList);
        }
      }
      getMyProperties();
    };
    fetch();
    //  getMyProperties();
  }, []);
  const workData = async (answersPrefer, getQuestionList) => {
    if (getQuestionList.message.preferencesList != undefined) {
      let questionList = getQuestionList.message.preferencesList;
      let workAddressArray = _.filter(questionList, function (o) {
        return o.question == "Work address";
      });
      let workAddress = {
        lat: answersPrefer[workAddressArray[0].preferenceUId].latitude,
        lng: answersPrefer[workAddressArray[0].preferenceUId].longitude,
        workAddress:
          answersPrefer[workAddressArray[0].preferenceUId] != undefined
            ? answersPrefer[workAddressArray[0].preferenceUId].preferencesanswer
            : "",
      };
      // console.log(workAddress, "lateworkAddress");
      await setState((prevState) => ({
        ...prevState,
        workAddress: workAddress,
      }));
      await localStorage.setItem(
        "workAddress",
        JSON.stringify(answersPrefer[workAddressArray[0].preferenceUId])
      );
    }
  };
  const getAssignmentProperties = async () => {
    let req = {
      transfereeId: 1,
    };
  };
  useEffect(() => {
    const fetch = async () => {
      let subscriptioncode =
        localStorage.getItem("loginSubscribeDetails") != null
          ? JSON.parse(localStorage.getItem("loginSubscribeDetails"))
              .subscriptioncode
          : "";
      await setState((prevState) => ({
        ...prevState,
        subscriptioncode: subscriptioncode,
      }));
      await method();
    };
    //$("#myModal").modal("show");
    fetch();
    return () => {
      _isMounted = false;
    };
  }, []);
  const moreInfoWithDetails = async (row) => {
    await getMoreInfo(row);
  };

  const changeActiveImg = (id) => {
    $(".thumb").removeClass("active");
    $(".carousel-item").removeClass("active");
    $("#MIG" + id).addClass("active");
    $("#thumb" + id).addClass("active");
  };
  const handleDateChange = async (editDate) => {
    await setState((prevState) => ({
      ...prevState,
      EditMoveDate: new Date(editDate),
      availableTillDate: moment(editDate).format("YYYY/MM/DD"),
    }));
  };

  const onImageChangeHandler = async (event) => {
    event.preventDefault();
    let files = event.target.files;
    if (files.length > 10) {
      await setState((prevState) => ({
        ...prevState,
        uploadImageError: "Only 5 images can be uploaded at a time.",
      }));
      $("#optionimages").addClass("is-invalid");
    } else {
      let err = "";
      const types = ["image/png", "image/jpeg", "image/gif"];
      const data = new FormData();
      for (var x = 0; x <= 10 - state.uploadImages.length; x++) {
        if (files[x] != undefined) {
          if (types.every((type) => files[x].type !== type)) {
            err += "Only supported png, jpeg, gif files format supported.";
          } else {
            data.append("file", files[x]);
          }
        }
      }
      if (err !== "") {
        await setState((prevState) => ({
          ...prevState,
          uploadImageError: err,
        }));
        $("#propertyImage").addClass("is-invalid");
      } else {
        await setState((prevState) => ({
          ...prevState,
          uploadImageError: "",
          uploadLoader: true,
        }));
        $("#propertyImage").removeClass("is-invalid");
        let userUniqueId = state.userUniqueId;
        _.forEach(files, function (file) {
          let timestamp = Date.now().toString();
          let path = `userinfo/${userUniqueId}/${timestamp}-lg`;
        });
      }
    }
  };
  /** image upload */
  const onChangeTypes = async (type, value) => {
    // console.log(type, value, "=====value");
    let inputVal = value;
    let inputObject = {};
    inputObject[type] = inputVal.toString();
    if (inputVal == null || inputVal == "") {
      $("#" + type).addClass("is-invalid");
    } else {
      $("#" + type).removeClass("is-invalid");
    }
    setState((prev) => ({ ...prev, [type]: value }));
  };
  const onChange = async (e) => {
    await setState((prevState) => ({
      ...prevState,
      isinterested: e.target.value,
    }));
  };
  const compareFunc = async (row) => {
    let updatecompareProperties = Object.assign([], state.compareProperties);
    if (updatecompareProperties[row.propertyanalyticsuniqueId] == undefined) {
      updatecompareProperties[row.propertyanalyticsuniqueId] = row;
    } else {
      delete updatecompareProperties[row.propertyanalyticsuniqueId];
    }
    if (Object.keys(updatecompareProperties).length <= 4)
      await setState((prevState) => ({
        ...prevState,
        compareProperties: updatecompareProperties,
      }));
  };
  const onClickCompare = async (e) => {
    if (
      Object.keys(state.compareProperties).length < 2 ||
      Object.keys(state.compareProperties).length > 4
    ) {
      confirmAlert({
        title: "",
        message:
          "Please select a minimum of 2 and a maximum of 4 rentals to compare. You can select the rentals to compare by clicking on them.",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    } else {
      //setModalOpen(true);
      // console.log(state.compareProperties, "state.compareProperties");
      $(function () {
        $("document").ready(async function () {
          let finalObjectCampare = [];
          await setState((prevState) => ({
            ...prevState,
            finalObjectCampare: [],
          }));
          Object.keys(state.compareProperties).map(
            async (rowvalue, valueObject) => {
              let data = {
                latitude: Number(state.compareProperties[rowvalue].latitude),
                longitude: Number(state.compareProperties[rowvalue].longitude),
                state_name: state.compareProperties[rowvalue].state,
                city_name: state.compareProperties[rowvalue].city,
                city: state.compareProperties[rowvalue].city,
                address: state.compareProperties[rowvalue].address,
              };
              // console.log(data, "datafor walkscore");
              let walkScoreDetails = await AppController.wallsckoreFun(data);
              // console.log(walkScoreDetails, "walkscoredetails");
              state.compareProperties[rowvalue].walkscore = "0";
              state.compareProperties[rowvalue].bikescore = "0";
              state.compareProperties[rowvalue].soundscore = "0";
              let commuteDistance =
                state.workAddress != ""
                  ? getDistance(
                      {
                        lat: state.compareProperties[rowvalue].latitude,
                        lng: state.compareProperties[rowvalue].longitude,
                      },
                      state.workAddress
                    )
                  : 0;
              // console.log(
              //   state.compareProperties,
              //   " state.compareProperties",
              //   state.workAddress
              // );
              state.compareProperties[rowvalue].commutedistance =
                commuteDistance;
              if (Object.keys(walkScoreDetails).length > 0) {
                if (
                  walkScoreDetails[0] != undefined &&
                  walkScoreDetails[0].Score != undefined
                ) {
                  state.compareProperties[rowvalue].walkscore =
                    walkScoreDetails[0].Score.toString();
                }
                if (
                  walkScoreDetails[1] != undefined &&
                  walkScoreDetails[1].Score != undefined
                ) {
                  state.compareProperties[rowvalue].bikescore =
                    walkScoreDetails[1].Score.toString();
                }
              }
              finalObjectCampare.push(state.compareProperties[rowvalue]);

              if (
                Object.keys(state.compareProperties).length ==
                finalObjectCampare.length
              )
                await setState((prevState) => ({
                  ...prevState,
                  finalObjectCampare: finalObjectCampare,
                }));
            }
          );

          $("#compare-community-Modal").modal("show");
        });
      });
    }
  };
  const method = async () => {
    let getSessionData = await LoginStore.getLoginInfo({});
    let userObject = {
      transfereeuniqeuid: getSessionData.userUniqueId,
      istemporary: getSessionData.istemporary,
    };
    let getQuestionList = await ApiServices.GetPreferencesList(userObject);
    let userPreferanceObject = {
      transfereeuniqeuid: getSessionData.userUniqueId,
      istemporary: getSessionData.istemporary,
    };
    let userSavedPreferances = await ApiServices.GetTransfereePreferences(
      userPreferanceObject
    );
    let answersPrefer = [];
    if (userSavedPreferances.error == 0) {
      let userAnswerPreferanceObject = userSavedPreferances.message.preferences;
      if (userAnswerPreferanceObject != undefined) {
        if (userAnswerPreferanceObject.length > 0) {
          _.forEach(userAnswerPreferanceObject, function (answerObject) {
            answersPrefer[answerObject.preferencesmasteruniqueid] =
              answerObject;
          });
          let sampleAnswerArray = _.filter(
            getQuestionList.message.preferencesList,
            function (o) {
              return answersPrefer[o.preferenceUId] == undefined;
            }
          );
          if (sampleAnswerArray.length > 0) {
            let obj = sampleAnswerArray[0];
            obj.preferencesanswer = "";
            answersPrefer[sampleAnswerArray[0].preferenceUId] = obj;
          }
          await setState((prevState) => ({
            ...prevState,
            answersPrefer: answersPrefer,
          }));
        } else {
          _.forEach(
            getQuestionList.message.preferencesList,
            function (answerObject) {
              let answerDefaultVal = "";
              if (answerObject.type == "money-range") {
                answerDefaultVal = "500, 1000";
              }
              if (answerObject.type == "Time-range") {
                answerDefaultVal = "15, 30";
              }
              answerObject.preferencesanswer = answerDefaultVal;
              answerObject.transfereeuniqeuid = getSessionData.userUniqueId;
              answerObject.preferencesmasteruniqueid =
                answerObject.preferenceUId;
              answersPrefer[answerObject.preferenceUId] = answerObject;
            }
          );
          await setState((prevState) => ({
            ...prevState,
            answersPrefer: answersPrefer,
          }));
        }
      }
    }
    if (getQuestionList.message.preferencesList != undefined) {
      let _this = this;
      let questionList = getQuestionList.message.preferencesList;
      let commuteTimeArray = _.filter(questionList, function (o) {
        return o.question == "Commute time";
      });

      let Budget =
        answersPrefer["99b770d5-6fad-4bd3-8ae1-3baaa57d1cff"] != undefined
          ? answersPrefer[
              "99b770d5-6fad-4bd3-8ae1-3baaa57d1cff"
            ].preferencesanswer.split(",")[1]
          : 0;
      console.log(Budget, "Budget");
      let commuteTime = answersPrefer[commuteTimeArray[0].preferenceUId];
      if (commuteTime?.preferencesanswer != undefined)
        await setState((prevState) => ({
          ...prevState,
          commuteTime: commuteTime.preferencesanswer.split(",")[1],
        }));

      let workAddressArray = _.filter(questionList, function (o) {
        return o.question == "Work address";
      });
      await localStorage.setItem(
        "address",
        state.answersPrefer[workAddressArray[0].preferenceUId]
          ?.preferencesanswer
      );

      let workAddress = {
        lat: state.answersPrefer[workAddressArray[0].preferenceUId]?.latitude,
        lng: state.answersPrefer[workAddressArray[0].preferenceUId]?.longitude,
        workAddress:
          state.answersPrefer[workAddressArray[0].preferenceUId] != undefined
            ? state.answersPrefer[workAddressArray[0].preferenceUId]
                .preferencesanswer
            : "",
      };

      await setState((prevState) => ({
        ...prevState,
        workAddress: workAddress,
        preferenecesBudget: Budget,
      }));
      getMyProperties();
    }
  };
  const rad = (x) => {
    return (x * Math.PI) / 180;
  };
  const activeProperty = async (row) => {
    confirmAlert({
      title: "",
      message: "Are you sure you want to add this Rental back to your list?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            confirmActive(row);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };
  const confirmActive = async (row) => {
    let propertyObjectDelete2 = {
      transfereeuniqueId: state.userUniqueId,
      propertyuniqueId: row.propertyuniqueId,
      propertyanalyticsuniqueId: row.propertyanalyticsuniqueId,
      isdelete: false,
      isactiverents: true,
    };

    let propertyObjectDelete = {
      propertyanalyticsuniqueId: row.propertyanalyticsuniqueId,
      transfereeuniqueId: state.userUniqueId,
      apiId: row.apiId,
      source: "my_property",
      city: row.city,
      isdelete: false,
      propertyname: row.propertyname,
      address: row.address,
      state: row.state,
      latitude: row.latitude,
      longitude: row.longitude,
      propertylink: row.propertylink,
      notes: row.notes,
      isIncluded: false,
      amenities: row.amenities,
      monthlyrent: row.monthlyrent,
      hometype: row.hometype,
      isDelete: false,
      isactive: row.isactive,
      bedrooms: row.bedrooms,
      bathrooms: row.bathrooms,
      unitnumber: row.unitnumber,
      flooring: row.flooring,
      sqfeet: row.sqfeet,
      virtual360tour: row.virtual360tour,
      property_image: row.property_image,
      available_till_date: row.available_till_date,
      isinterested: true,
    };

    let addPropertyResultDelete = await ApiServices.UpdateProperty(
      propertyObjectDelete
    );
    await setState((prevState) => ({
      ...prevState,
      isactiverents: true,
    }));
    if (addPropertyResultDelete.error == 0) {
      $("document").ready(function () {
        $("#add-property-modal").find("input").val("").end();
      });
      getMyProperties();
      setState((prevState) => ({
        ...prevState,
        // ShowSuccess: true,
        loader: false,
        propertySuccess: true,
        propertyName: "",
        propertyLink: "",
        address: "",
        propertyNotes: "",
        uploadImages: [],
        availableTillDate: null,
        EditMoveDate: null,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        isPropertyError: true,
        loader: false,
        errorMessage: addPropertyResultDelete.message.status,
      }));
    }
    setTimeout(async () => {
      await setState((prevState) => ({
        ...prevState,
        isactiverents: false,
      }));
    }, 3000);
  };
  const deleteProperty = async (row) => {
    confirmAlert({
      title: "",
      message:
        "Are you sure you want to remove this Rental option from your list?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            confirm(row);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };
  const confirm = async (row) => {
    let propertyObjectDelete2 = {
      transfereeuniqueId: state.userUniqueId,
      propertyuniqueId: row.propertyuniqueId,
      propertyanalyticsuniqueId: row.propertyanalyticsuniqueId,
      isdelete: true,
    };

    let propertyObjectDelete = {
      propertyanalyticsuniqueId: row.propertyanalyticsuniqueId,
      transfereeuniqueId: state.userUniqueId,
      apiId: row.apiId,
      source: "my_property",
      city: row.city,
      isdelete: false,
      propertyname: row.propertyname,
      address: row.address,
      state: row.state,
      latitude: row.latitude,
      longitude: row.longitude,
      propertylink: row.propertylink,
      notes: row.notes,
      isIncluded: false,
      amenities: row.amenities,
      monthlyrent: row.monthlyrent,
      hometype: row.hometype,
      isDelete: false,
      isactive: row.isactive,
      bedrooms: row.bedrooms,
      bathrooms: row.bathrooms,
      unitnumber: row.unitnumber,
      flooring: row.flooring,
      sqfeet: row.sqfeet,
      virtual360tour: row.virtual360tour,
      property_image: row.property_image,
      available_till_date: row.available_till_date,
      isinterested: false,
    };

    let addPropertyResultDelete = await ApiServices.UpdateProperty(
      propertyObjectDelete
    );
    await setState((prevState) => ({
      ...prevState,
      isDelete: true,
    }));
    // navigate("/myProperty");
    if (addPropertyResultDelete.error == "0") {
      $("document").ready(function () {
        $("#add-property-modal").find("input").val("").end();
      });
      getMyProperties();
      setState((prevState) => ({
        ...prevState,
        // ShowSuccess: true,
        loader: false,
        propertySuccess: true,
        propertyName: "",
        propertyLink: "",
        address: "",
        propertyNotes: "",
        uploadImages: [],
        availableTillDate: null,
        EditMoveDate: null,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        isPropertyError: true,
        loader: false,
        errorMessage: addPropertyResultDelete.message.status,
      }));
    }
    setTimeout(async () => {
      await setState((prevState) => ({
        ...prevState,
        isDelete: false,
      }));
    }, 3000);
  };
  const getDistance = (p1, p2) => {
    //console.log(p1, p2, "dist");
    var R = 6378137; // Earth’s mean radius in meter
    var dLat = rad(p2.lat - p1.lat);
    var dLong = rad(p2.lng - p1.lng);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(rad(p1.lat)) *
        Math.cos(rad(p1.lat)) *
        Math.sin(dLong / 2) *
        Math.sin(dLong / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return (d * 0.000741371).toFixed(1); // returns the distance in meter
  };
  const openMyPropertiesNoteInterested = () => {
    //setInactModal(true);
    $(function () {
      $("document").ready(async function () {
        $("#not-MyProperties-interested-community-Modal").modal("show");
      });
    });
  };

  const getMoreInfo = async (row) => {
    let GetTransfereePropertiesObject = {
      transfereeuniqueId: row.transfereeUId,
      propertyuniqueId: row.propertyuniqueId,
    };
    let GetTransfereeProperties = await ApiServices.GetTransfereeProperties(
      GetTransfereePropertiesObject
    );
    if (GetTransfereeProperties.error == 0) {
      if (GetTransfereeProperties.message != undefined) {
        if (
          GetTransfereeProperties.message.transfereepropertyList[0] != undefined
        ) {
          let propertyDetails =
            GetTransfereeProperties.message.transfereepropertyList[0];
          (row.pdffilepath = propertyDetails.pdffilepath),
            (row.pdfupdatedat = propertyDetails.pdfupdatedat),
            await localStorage.setItem(
              "myPropertyDetails",
              JSON.stringify(row)
            );
          // window.open("/my_property_details");
          navigate("/my_property_details");
        }
      }
    }
  };
  const onClickmap = async () => {
    setMapmodal(true);
    $("document").ready(function () {
      $("#propertymap").modal("show");
    });
  };
  const Mclose = () => {
    // console.log("close icon");
    $("document").ready(function () {
      $("#propertymap").modal("hide");
    });
    setState((prev) => ({ ...prev }));
    setMapmodal(false);
  };
  const actionButton = (type) => {
    console.log(type, "type");
    if (type == "active") {
      getMyProperties();
      setState((prev) => ({ ...prev, button: type }));
    } else {
      getMyProperties();
      setState((prev) => ({ ...prev, button: type }));
    }
  };
  const children = [];
  var dataSource = ["APARTMENT", "CONDO", "TOWNHOME", "HOUSE"];
  for (let i = 0; i < dataSource.length; i++) {
    children.push(
      <Select.Option key={dataSource[i]}>{dataSource[i]}</Select.Option>
    );
  }
  const amenitieslist = [];
  var dataSource1 = [
    "Has AC",
    "Business Center",
    "Carpeted",
    "Ceiling Fan",
    "Clubhouse",
    "Concierge",
    "Fireplace",
    "Fitness Center",
    "Gated Community",
    "Granite Counters",
    "Hardwood Floors",
    "Laundry Facility",
    "Non Smoking",
    "Parking / Garage",
    "Patio",
    "Pet Friendly",
    "Pool",
    "Renovated",
    "Spa",
    "Stainless Appliances",
    "Has Storage",
    "Walk-in Closet",
    "Washer / Dryer",
    "Playground / Tot Lot",
  ];
  for (let i = 0; i < dataSource1.length; i++) {
    amenitieslist.push(
      <Select.Option key={dataSource1[i]}>{dataSource1[i]}</Select.Option>
    );
  }
  const bedrromslist = [];
  var bedrromslist1 = ["1", "2", "3", "4+"];
  for (let i = 0; i < bedrromslist1.length; i++) {
    bedrromslist.push(
      <Select.Option key={bedrromslist1[i]}>{bedrromslist1[i]}</Select.Option>
    );
  }
  const bothroomslist = [];
  var bothroomslist1 = ["1", "1.5", "2", "2.5", "3", "3.5", "4", "4.5", "5+"];
  for (let i = 0; i < bothroomslist1.length; i++) {
    bothroomslist.push(
      <Select.Option key={bothroomslist1[i]}>{bothroomslist1[i]}</Select.Option>
    );
  }

  return (
    <div className="">
      <div className="container" style={{ minHeight: "500px" }}>
        <div className="my_properties_lists">
          <h1 className="main h1 mb-4">
            My Rentals
            <span className="cursor add-list">
              <button type="submit" className="btn" onClick={onClickmap}>
                <img
                  // src="/assets/images/homefinding-mapicon.svg"
                  src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/location_pin_2.svg"
                  width="28px"
                />
              </button>
              <button
                type="submit"
                className="btn theme-btn"
                style={{ marginRight: "10px" }}
                onClick={(e) => openModal(e)}
              >
                Set Priorities
              </button>

              <button
                type="submit"
                className="btn theme-btn"
                style={{ marginRight: "10px" }}
                onClick={(e) => onClickCompare(e)}
              >
                Compare
              </button>
              <button
                type="submit"
                className="btn theme-btn"
                onClick={onClickAddProperty}
              >
                Add Rental{" "}
                <i className="fa fa-plus-square-o" aria-hidden="true"></i>{" "}
              </button>
            </span>
          </h1>
          <MyPreferencesModal
            modalIsOpen={modalIsOpen}
            state={state}
            getMyProperties={getMyProperties}
            setModalIsOpen={setModalIsOpen}
          />
          <div className="">
            <button
              className={
                state.button == "active"
                  ? "btn tabs-btn active"
                  : "btn tabs-btn"
              }
              onClick={() => actionButton("active")}
            >
              Active
            </button>

            <button
              className={
                state.button == "inactive"
                  ? "btn tabs-btn active"
                  : "btn tabs-btn"
              }
              onClick={() => actionButton("inactive")}
            >
              Inactive
            </button>
          </div>
          {state.isDelete == true ? (
            <div>
              <div className="floating-label">
                <h6
                  style={{
                    //display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // height: "100vh",
                  }}
                  className="alert alert-success mt-3"
                >
                  Rental has been deleted Successfully
                </h6>
              </div>
            </div>
          ) : (
            ""
          )}
          {state.isactiverents == true ? (
            <div>
              <div className="floating-label">
                <h6
                  style={{
                    //display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // height: "100vh",
                  }}
                  className="alert alert-success mt-3"
                >
                  Rental has been moved to active section{" "}
                </h6>
              </div>
            </div>
          ) : (
            ""
          )}
          {/* {console.log(state.myPropertiesList, "propsimg")} */}
          {state.button == "active" && (
            <div>
              {state.myPropertiesList && state.myPropertiesList.length > 0 ? (
                <div className="main_grid">
                  {_.sortBy(state.myPropertiesList, function (obj) {
                    return parseInt(obj.propertyrankitems.matchpercent, 10);
                  })
                    .reverse()
                    .map((row, index) => {
                      return (
                        <div className="item" key={index}>
                          <span className="ScoreLabel">
                            <Popover
                              placement="leftTop"
                              content={
                                <div>
                                  {row.propertyrankitems.propertyrankingitems.map(
                                    (Pitem) => {
                                      return (
                                        <p
                                          key={
                                            "mmy" +
                                            index +
                                            Pitem.prioritylistitem
                                          }
                                        >
                                          {Pitem.prioritylistitem ==
                                          "Must Haves" ? (
                                            <Popover
                                              placement="leftTop"
                                              content={
                                                <div>
                                                  {Pitem.matcheditems.map(
                                                    (matcheditem) => {
                                                      return (
                                                        matcheditem.itemname !=
                                                          "" && (
                                                          <p
                                                            key={
                                                              "mmypps" +
                                                              index +
                                                              matcheditem.itemname
                                                            }
                                                          >
                                                            {
                                                              matcheditem.itemname
                                                            }
                                                            {matcheditem.ismatched ==
                                                              true && (
                                                              <i className="fa fa-check"></i>
                                                            )}
                                                            {matcheditem.ismatched ==
                                                              false && (
                                                              <i className="fa fa-times"></i>
                                                            )}
                                                          </p>
                                                        )
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              }
                                              title="Matched Must Haves"
                                              trigger="hover"
                                            >
                                              {Pitem.prioritylistitem}{" "}
                                              <i
                                                className="fa fa-info-circle"
                                                style={{ color: "#000000" }}
                                              ></i>
                                            </Popover>
                                          ) : (
                                            Pitem.prioritylistitem
                                          )}
                                          {Pitem.ismatched == true &&
                                            Pitem.isallmatched == false && (
                                              <i className="fa fa-check yellow_clr"></i>
                                            )}
                                          {Pitem.ismatched == true &&
                                            Pitem.isallmatched == true && (
                                              <i className="fa fa-check"></i>
                                            )}
                                          {Pitem.ismatched == true &&
                                            Pitem.isallmatched == null && (
                                              <i className="fa fa-check"></i>
                                            )}
                                          {Pitem.ismatched == false && (
                                            <i className="fa fa-times"></i>
                                          )}
                                        </p>
                                      );
                                    }
                                  )}
                                </div>
                              }
                              title="Matched Priorities"
                              trigger="hover"
                            >
                              {Math.trunc(row.propertyrankitems.matchpercent)}%
                            </Popover>
                          </span>
                          <input
                            type="checkbox"
                            id={"myCheckbox" + index}
                            onChange={() => compareFunc(row)}
                            checked={
                              state.compareProperties[
                                row.propertyanalyticsuniqueId
                              ] != undefined
                                ? true
                                : false
                            }
                          />
                          {/* {console.log(row.property_image.split(",")[0], "imgggg")} */}
                          <label htmlFor={"myCheckbox" + index}>
                            <img
                              src={
                                row.property_image != null &&
                                row.property_image != ""
                                  ? `${row.property_image.split(",")[0]}`
                                  : "https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/dtsecimg1.png"
                              }
                              alt=""
                            />
                            <div className="content">
                              <a
                                href="javascript:void(0)"
                                onClick={() => moreInfoWithDetails(row)}
                              >
                                {row.propertyname}{" "}
                                <i class="fa-regular fa-arrow-up-right"></i>{" "}
                              </a>
                              <p>
                                {" "}
                                {row.address}
                                {/* {row.city} {row.state} */}
                              </p>
                              <span>
                                <span>
                                  <a
                                    href="javascript:void(0)"
                                    onClick={() => onClickEditProperty(row)}
                                  >
                                    <i class="fa-regular fa-pen-to-square"></i>
                                  </a>
                                  &nbsp;&nbsp;
                                  <a
                                    href="javascript:void(0)"
                                    onClick={() => deleteProperty(row)}
                                  >
                                    <i class="fa-regular fa-trash-can"></i>
                                  </a>
                                </span>{" "}
                                {/* <a
                                  href="javascript:void(0)"
                                  className="linkColor"
                                >
                                  More
                                </a>{" "} */}
                              </span>
                            </div>
                          </label>
                        </div>
                      );
                    })}
                </div>
              ) : state.propertyLoader ? (
                <div className="min-height-500 divSpin">
                  <Spin />
                </div>
              ) : (
                <div className="main_grid_new empty-pro">
                  Please add Rentals from your own research here. ReloNavigator
                  will help you to choose housing option based on your criteria.
                </div>
              )}
            </div>
          )}
          {state.button == "inactive" && (
            <div>
              {state.myPropertiesListInactive &&
              state.myPropertiesListInactive.length > 0 ? (
                <div className="main_grid" style={{ borderTop: "none" }}>
                  {state.myPropertiesListInactive.map((row, index) => {
                    return (
                      <div className="item" key={"poup" + index}>
                        <span className="ScoreLabel">
                          <Popover
                            placement="leftTop"
                            content={
                              <div>
                                {row.propertyrankitems.propertyrankingitems.map(
                                  (Pitem) => {
                                    return (
                                      <p
                                        key={
                                          "mymmysa2" +
                                          index +
                                          Pitem.prioritylistitem
                                        }
                                      >
                                        {Pitem.prioritylistitem ==
                                        "Must Haves" ? (
                                          <Popover
                                            placement="leftTop"
                                            content={
                                              <div>
                                                {Pitem.matcheditems.map(
                                                  (matcheditem) => {
                                                    return (
                                                      <p
                                                        key={
                                                          "mmysa2pps" +
                                                          index +
                                                          matcheditem.itemname
                                                        }
                                                      >
                                                        {matcheditem.itemname}
                                                        {matcheditem.ismatched ==
                                                          true && (
                                                          <i className="fa fa-check"></i>
                                                        )}
                                                        {matcheditem.ismatched ==
                                                          false && (
                                                          <i className="fa fa-times"></i>
                                                        )}
                                                      </p>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            }
                                            title="Matched Must Haves"
                                            trigger="hover"
                                          >
                                            {Pitem.prioritylistitem}{" "}
                                            <i
                                              className="fa fa-info-circle"
                                              style={{ color: "#000000" }}
                                            ></i>
                                          </Popover>
                                        ) : (
                                          Pitem.prioritylistitem
                                        )}
                                        {Pitem.ismatched == true &&
                                          Pitem.isallmatched == false && (
                                            <i className="fa fa-check yellow_clr"></i>
                                          )}
                                        {Pitem.ismatched == true &&
                                          Pitem.isallmatched == true && (
                                            <i className="fa fa-check"></i>
                                          )}
                                        {Pitem.ismatched == true &&
                                          Pitem.isallmatched == null && (
                                            <i className="fa fa-check"></i>
                                          )}
                                        {Pitem.ismatched == false && (
                                          <i className="fa fa-times"></i>
                                        )}
                                      </p>
                                    );
                                  }
                                )}
                              </div>
                            }
                            title="Matched Priorities"
                            trigger="hover"
                          >
                            {Math.trunc(row.propertyrankitems.matchpercent)}%
                          </Popover>
                        </span>
                        <label htmlFor={"my2myCheckbox" + index}>
                          <img
                            src={
                              row.property_image != null &&
                              row.property_image != "" &&
                              row.property_image != undefined
                                ? row.property_image.split(",")[0]
                                : "https://relonavigator.s3-us-west-1.amazonaws.com/WebsiteImages/dtsecimg1.png"
                            }
                            alt=""
                          />
                          <div className="content">
                            <a
                              href="javascript:void(0)"
                              onClick={() => moreInfoWithDetails(row)}
                            >
                              {row.propertyname}{" "}
                              <i class="fa-regular fa-arrow-up-right"></i>{" "}
                            </a>
                            <p>
                              {row.address}
                              {/* {row.city} {row.state} */}
                            </p>
                            <span>
                              <span>
                                <a
                                  href="javascript:void(0)"
                                  onClick={() => activeProperty(row)}
                                >
                                  Active
                                </a>
                              </span>{" "}
                              {/* <a
                                href="javascript:void(0)"
                                className="linkColor"
                                onClick={() => moreInfoWithDetails(row)}
                              >
                                More
                              </a>{" "} */}
                            </span>
                          </div>
                        </label>
                      </div>
                    );
                  })}
                </div>
              ) : state.propertyLoader ? (
                <Spin />
              ) : (
                <div className="main_grid">No data available</div>
              )}
            </div>
          )}

          {/* {console.log(state.propertyLoader, "load")} */}

          <AddEditModal
            isOpen={isModalOpen}
            onClose={closeModall}
            state={state}
            setState={setState}
            getDistance={getDistance}
            getMyProperties={getMyProperties}
          />

          {/** details my property */}
          {state.showModal && (
            <div className="fade modal new_modal_pop" id="moreInfoModal">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="only_close">
                    <span className="pointer close" data-dismiss="modal">
                      {" "}
                      &times;{" "}
                    </span>
                  </div>

                  <div className="modal-body ">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="position-relative">
                          <div
                            id="carouselExampleFade"
                            className="carousel slide carousel-fade"
                            data-ride="carousel"
                          >
                            <div className="carousel-inner">
                              {state.modalpopupInfo.images.map(
                                (imgSignle, Imgindex) => {
                                  return (
                                    <div
                                      key={"tkey" + Imgindex}
                                      className={
                                        Imgindex == 0
                                          ? "carousel-item active"
                                          : "carousel-item "
                                      }
                                      id={"MIG" + Imgindex}
                                    >
                                      <img
                                        src={imgSignle}
                                        className="d-block w-100"
                                        alt="Second slide"
                                      />
                                    </div>
                                  );
                                }
                              )}
                            </div>
                            <a
                              className="carousel-control-prev"
                              href="#carouselExampleFade"
                              role="button"
                              data-slide="prev"
                            >
                              <span
                                className="carousel-control-prev-icon"
                                aria-hidden="true"
                              ></span>
                              <span className="sr-only">Previous</span>
                            </a>
                            <a
                              className="carousel-control-next"
                              href="#carouselExampleFade"
                              role="button"
                              data-slide="next"
                            >
                              <span
                                className="carousel-control-next-icon"
                                aria-hidden="true"
                              ></span>
                              <span className="sr-only">Next</span>
                            </a>
                            <ol className="carousel-indicators">
                              {state.modalpopupInfo.images.map(
                                (thumbimgSignle, thumbImgindex) => {
                                  return (
                                    <li
                                      data-target="#carousel-thumb"
                                      key={"thumbkey" + thumbImgindex}
                                      data-slide-to={thumbImgindex}
                                      id={"thumb" + thumbImgindex}
                                      onClick={() =>
                                        changeActiveImg(thumbImgindex)
                                      }
                                      className={
                                        thumbImgindex == 0
                                          ? "thumb active"
                                          : "thumb active"
                                      }
                                    >
                                      {" "}
                                      <img
                                        className="d-block w-100"
                                        src={thumbimgSignle}
                                      />
                                    </li>
                                  );
                                }
                              )}
                            </ol>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mrgtop-20">
                        <div className="popupmaintext">
                          <div className="form-group row">
                            <label className="col-4 col-form-label">
                              Property Name{" "}
                            </label>
                            <div className="col-8">
                              <label className="cLabel-label col-form-label">
                                {state.modalpopupInfo.propertyname}
                              </label>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-4 col-form-label">
                              Address{" "}
                            </label>
                            <div className="col-8">
                              <label className="cLabel-label col-form-label">
                                {state.modalpopupInfo.address}
                              </label>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-4 col-form-label">
                              Property Link{" "}
                            </label>
                            <div className="col-8">
                              <label className="cLabel-label col-form-label">
                                <a
                                  href={state.modalpopupInfo.propertylink}
                                  target="_blank"
                                >
                                  {state.modalpopupInfo.propertylink}{" "}
                                </a>
                              </label>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-4 col-form-label">
                              Available Date{" "}
                            </label>
                            <div className="col-8">
                              <label className="cLabel-label col-form-label">
                                {state.modalpopupInfo.available_till_date !=
                                null
                                  ? moment(
                                      state.modalpopupInfo.available_till_date
                                    ).format("MM/DD/YYYY")
                                  : "---"}{" "}
                              </label>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-4 col-form-label">
                              Notes{" "}
                            </label>
                            <div className="col-8">
                              <label className="cLabel-label col-form-label">
                                {state.modalpopupInfo.notes}{" "}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/** end details */}
          <CompareModal
            isOpen={modalOpen}
            onClose={closeModal}
            goToMap={goToMap}
            state={state}
          />
          <div className="modal-dialog modal-lg">
            <InactiveModal
              isOpen={inactModal}
              onClose={closeInact}
              state={state}
              activeProperty={activeProperty}
              moreInfoWithDetails={moreInfoWithDetails}
            />
          </div>
        </div>
        {state.subscriptioncode != "ReloNavigator" &&
          state.subscriptioncode != "" &&
          state.subscriptioncode != undefined &&
          state.subscriptioncode != null && (
            <SaProperties
              compareFunc={compareFunc}
              PrioritiesStatus={state.PrioritiesStatus}
              compareProperties={state.compareProperties}
            />
          )}
        <MyPreferencesModal {...state} getMyProperties={getMyProperties} />
        {state.subscriptioncode == "ReloNavigator" &&
          state.subscriptioncode != "" &&
          state.subscriptioncode != undefined &&
          state.subscriptioncode != null && (
            <div className="homefinding-bottom-view">
              <p className="para">
                Use the following websites to help source housing options. Click
                on the links at the bottom to get started. Then, just copy and
                paste the listing's URL into the Add Rental button at the top.{" "}
              </p>

              <ul>
                <li>
                  <a href="https://www.apartments.com/" target="_blank">
                    {" "}
                    <img src="/assets/images/logo_apartments.png" alt="" />{" "}
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.craigslist.org/about/sites#US"
                    target="_blank"
                  >
                    {" "}
                    <img src="/assets/images/logo_craigslist.png" alt="" />{" "}
                  </a>
                </li>
                <li>
                  <a href="https://www.realtor.com/" target="_blank">
                    {" "}
                    <img src="/assets/images/logo_realtor.png" alt="" />{" "}
                  </a>
                </li>
                <li>
                  <a href="https://www.zillow.com/" target="_blank">
                    {" "}
                    <img src="/assets/images/logo_zillow.png" alt="" />{" "}
                  </a>
                </li>
                <li>
                  <a href="https://www.apartmentlist.com/" target="_blank">
                    {" "}
                    <img
                      src="/assets/images/logo_apartmentlist.png"
                      alt=""
                    />{" "}
                  </a>
                </li>
                <li>
                  <a href="https://hotpads.com/" target="_blank">
                    {" "}
                    <img src="/assets/images/logo_hotpads.png" alt="" />{" "}
                  </a>
                </li>
                <li>
                  <a href="https://www.rent.com/" target="_blank">
                    {" "}
                    <img src="/assets/images/logo_rent.com.png" alt="" />{" "}
                  </a>
                </li>
                <li>
                  <a href="https://www.zumper.com/" target="_blank">
                    {" "}
                    <img src="/assets/images/logo_zumper.png" alt="" />{" "}
                  </a>
                </li>
              </ul>
              <p className="para">
                When exploring external sites, please exercise caution and be
                aware of the potential fraudulent listings. These are some
                popular housing sites and have not been fully vetted by
                ReloNavigator.{" "}
              </p>
            </div>
          )}
      </div>

      <Footer />
      <div
        id="propertymap"
        className="modal fadeInDown animated new_modal_pop modal-fullscreen-xl"
        role="dialog"
        style={{ zIndex: 1060 }}
        data-backdrop="static"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="width-90per">
              <div className="only_close">
                <span
                  className="pointer close"
                  data-dismiss="modal"
                  onClick={Mclose}
                >
                  ×
                </span>
              </div>
              <Maplist mapModal={mapModal} />
            </div>
          </div>
        </div>
      </div>
      <div
        id="comparemap"
        className="modal fadeInDown animated new_modal_pop modal-fullscreen-xl"
        role="dialog"
        style={{
          transition: "none",
          zIndex: 1060,
        }}
        data-backdrop="static"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="width-90per mt-0">
              <div className="only_close">
                <span
                  className="pointer close"
                  data-dismiss="modal"
                  onClick={() => Close()}
                >
                  ×
                </span>
              </div>

              {state.finalObjectCampare &&
                state.finalObjectCampare.length > 0 && (
                  <CompareMapList
                    finalObjectCampare={state.finalObjectCampare}
                    compareMap={compareMap}
                  />
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MyProperties;
