import React, { useEffect, useState } from "react";
// import "./resorce.css";
// import "./modalPopUp/popUpStyles.css";
import { Link } from "react-router-dom";
import * as $ from "jquery";
import LoginStore from "../../../../services/thirdPartyService";
// import Modal from "react-modal";
import { Select, Modal } from "antd";
import PopUpData from "./popUp";
import Header from "../../../common/header/Header";
import Footer from "../../../common/footer/Footer";
import SocialPopUp from "./modalPopUp/socialPopUp";
import DriverLicensePopUp from "./modalPopUp/driverLicensePopUp";
import RegisterPopUp from "./modalPopUp/registerPopUp";
import ForwardingPopUp from "./modalPopUp/forwardingPopUp";
import BankingPopUp from "./modalPopUp/bankingPopUp";
import UtilitiesPopUp from "./modalPopUp/utilitiesPopUP";
import MovingTips from "./modalPopUp/movingTipsPopUp";
import PackingRecommendationsPopUpTips from "./modalPopUp/PackingRecommendationsPopUp";
import NonTAMovingTips from "./modalPopUp/nonTAmovingTipsPopUp";
import NonTAPackingRecommendations from "./modalPopUp/nonTAPackingRecommendatios";
import RentersInsuranceModal from "./modalPopUp/rentersInsurancePopUp";
import RentalApplicationProcessModal from "./modalPopUp/rentalApplicationPopUp";
import TenantRightsPopUP from "./modalPopUp/tenantRightsPopUp";
import RentalScamsPopUp from "./modalPopUp/rentalScamsPopUp";

function ResourceLanding() {
  const [showModal, setShowModal] = useState(false);
  const [activePopUp, setActivePopUp] = useState("");
  const [sessionData, setSesstionData] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    let getSessionData = LoginStore.getLoginInfo({})
      .then((result) => {
        // console.log(result, "result");
        setSesstionData(result); // Assuming `setSesstionData` is a function to set session data
      })
      .catch((error) => {
        console.error(error); // Handling errors if the promise is rejected
      });
  }, []);

  const handlePopUp = (typeMenu) => {
    // setShowModal(true);
    if (typeMenu == "frequentlyQuestions") {
      location.href = "/rental-faq";
    } else if (typeMenu == "tenantRights") {
      $(function () {
        $("document").ready(async function () {
          $("#TenantRights").modal("show");
        });
      });
    } else if (typeMenu == "rentalScams") {
      $(function () {
        $("document").ready(async function () {
          $("#RentalScams").modal("show");
        });
      });
    } else if (typeMenu == "rentersinsurance") {
      $(function () {
        $("document").ready(async function () {
          $("#RentersInsurance").modal("show");
        });
      });
    } else if (typeMenu == "rentalapplicationprocess") {
      $(function () {
        $("document").ready(async function () {
          $("#RentalApplicationProcess").modal("show");
        });
      });
    } else if (typeMenu == "nontamovingtips") {
      $(function () {
        $("document").ready(async function () {
          $("#NonTAModalMovingTips").modal("show");
        });
      });
    } else if (typeMenu == "nontaPackingRecommendations") {
      $(function () {
        $("document").ready(async function () {
          $("#NonTAPackingRecommendations").modal("show");
        });
      });
    } else if (typeMenu == "movingtips") {
      $(function () {
        $("document").ready(async function () {
          $("#myModalMovingTips").modal("show");
        });
      });
    } else if (typeMenu == "PackingRecommendations") {
      $(function () {
        $("document").ready(async function () {
          $("#PackingRecommendations").modal("show");
        });
      });
      // setActivePopUp("social");
    } else if (typeMenu == "social") {
      $(function () {
        $("document").ready(async function () {
          $("#myModalSocialSecurity").modal("show");
        });
      });
      // setActivePopUp("social");
    } else if (typeMenu == "driverlicense") {
      $(function () {
        $("document").ready(async function () {
          $("#myModalDriversLicense").modal("show");
        });
      });
      // setActivePopUp("driverlicense");
    } else if (typeMenu == "register") {
      $(function () {
        $("document").ready(async function () {
          $("#myModalRegisteringEmbassy").modal("show");
        });
      });
      // setActivePopUp("register");
    } else if (typeMenu == "forwarding") {
      $(function () {
        $("document").ready(async function () {
          $("#myModalForwardingMail").modal("show");
        });
      });
      // setActivePopUp("forwarding");
    } else if (typeMenu == "banking") {
      $(function () {
        $("document").ready(async function () {
          $("#myModalsettingUpBanking").modal("show");
        });
      });
      // setActivePopUp("banking");
    } else {
      $(function () {
        $("document").ready(async function () {
          $("#myModalsettingUpUtilities").modal("show");
        });
      });
      // setActivePopUp("utilities");
    }
  };
  const rentCalculator = () => {
    location.href = "/rentCalculator";
  };

  return (
    <div id="outer-container">
      {/* <Header /> */}
      <div className="container">
        <div className="resources-tab-view">
          <h1>Resources</h1>
          <p>
            Welcome to our Resources Section! Explore a curated collection of
            tools and information to help you navigate your relocation journey
            with ease.
          </p>
          <div className="">
            <h2>Resumes</h2>
            <div className="text-box">
              <p>
                Please use the below resources to generate your personalized
                Renters Resume or Pet Resume. Click on Create and enter the
                information, you will be able to download or Email the resumes.
              </p>
              <div className="view-details">
                <div className="item">
                  <div className="item-image">
                    <img src="/assets/images/pet-resume.png" alt="" />
                  </div>
                  <div className="item-text">
                    <p>Pet Resume</p>
                    <Link to="/petresume">
                      <button className="btn" type="button">
                        Create
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="item">
                  <div className="item-image">
                    <img src="/assets/images/renter-resume.png" alt="" />
                  </div>
                  <div className="item-text">
                    <p>Renters Resume</p>
                    <Link to="/rentersresume">
                      <button className="btn" type="button">
                        Create
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <h2>Housing Resources</h2>
            <div className="botton-tab-view">
              <div className="item" onClick={() => rentCalculator()}>
                <span>
                  <img
                    src="/assets/images/calculator.png"
                    alt=""
                    width={"95px"}
                  />
                </span>
                <h3>Rent Affordability calculator</h3>
              </div>
              <div
                className="item"
                onClick={() => handlePopUp("rentersinsurance")}
              >
                <span>
                  <img
                    src="/assets/images/insurance.png"
                    alt=""
                    width={"95px"}
                  />
                </span>
                <h3>Renters Insurance</h3>
              </div>
              <div
                className="item"
                onClick={() => handlePopUp("rentalapplicationprocess")}
              >
                <span>
                  <img
                    src="/assets/images/application-proces.png"
                    alt=""
                    width={"95px"}
                  />
                </span>
                <h3>Rental Application Process</h3>
              </div>
              <div className="item" onClick={() => handlePopUp("tenantRights")}>
                <span>
                  <img
                    src="/assets/images/flat-lawyers-day-background.png"
                    alt=""
                    width={"95px"}
                  />
                </span>
                <h3>Tenant Rights and Responsibilities</h3>
              </div>
              <div className="item" onClick={() => handlePopUp("rentalScams")}>
                <span>
                  <img src="/assets/images/Scam.png" alt="" width={"95px"} />
                </span>
                <h3>Rental Scams</h3>
              </div>
              <div
                className="item"
                onClick={() => handlePopUp("frequentlyQuestions")}
              >
                <span>
                  <img src="/assets/images/faq.png" alt="" width={"95px"} />
                </span>
                <h3>Frequently Asked Questions</h3>
              </div>
            </div>

            <h2 className="pt-4">Settling In Resources</h2>
            <div className="botton-tab-view">
              {sessionData.istemporary == true ? (
                <div className="item" onClick={() => handlePopUp("movingtips")}>
                  <span>
                    <img
                      src="/assets/images/moving-tips.png"
                      alt=""
                      width={"95px"}
                    />
                  </span>
                  <h3>Moving Tips</h3>
                </div>
              ) : (
                ""
              )}
              {sessionData.istemporary == true && (
                <div
                  className="item"
                  onClick={() => handlePopUp("PackingRecommendations")}
                >
                  <span>
                    <img
                      src="/assets/images/order-tracking.png"
                      alt=""
                      width={"95px"}
                    />
                  </span>
                  <h3>Packing Recommendations</h3>
                </div>
              )}

              {sessionData.istemporary == false && (
                <div
                  className="item"
                  onClick={() => handlePopUp("nontamovingtips")}
                >
                  <span>
                    <img
                      src="/assets/images/moving-tips.png"
                      alt=""
                      width={"95px"}
                    />
                  </span>
                  <h3>Moving Tips</h3>
                </div>
              )}
              {sessionData.istemporary == false && (
                <div
                  className="item"
                  onClick={() => handlePopUp("nontaPackingRecommendations")}
                >
                  <span>
                    <img
                      src="/assets/images/order-tracking.png"
                      alt=""
                      width={"95px"}
                    />
                  </span>
                  <h3>Packing Recommendations</h3>
                </div>
              )}

              <div className="item" onClick={() => handlePopUp("social")}>
                <span>
                  <img
                    src="/assets/images/social-security.svg"
                    alt=""
                    width={"95px"}
                  />
                </span>
                <h3>Social Security</h3>
              </div>

              <div
                className="item"
                onClick={() => handlePopUp("driverlicense")}
              >
                <span>
                  <img
                    src="/assets/images/driver-licence.svg"
                    alt=""
                    width={"95px"}
                  />
                </span>
                <h3> Driver’s License</h3>
              </div>

              <div className="item" onClick={() => handlePopUp("register")}>
                <span>
                  <img src="/assets/images/embacy.png" alt="" width={"90px"} />
                </span>
                <h3> Registering with an Embassy</h3>
              </div>

              <div className="item" onClick={() => handlePopUp("forwarding")}>
                <span>
                  <img
                    src="/assets/images/email-forward.png"
                    alt=""
                    width={"85px"}
                  />
                </span>
                <h3> Forwarding Your Mail</h3>
              </div>

              <div className="item" onClick={() => handlePopUp("banking")}>
                <span>
                  <img
                    src="/assets/images/banking.svg"
                    alt=""
                    width={"130px"}
                  />
                </span>
                <h3> Setting Up Banking</h3>
              </div>
              <div className="item" onClick={() => handlePopUp("utilities")}>
                <span>
                  <img
                    src="/assets/images/utilities.svg"
                    alt=""
                    width={"95px"}
                  />
                </span>
                <h3> Setting Up Utilities</h3>
              </div>
            </div>

            <div style={{ height: "50px" }}></div>
          </div>
        </div>
      </div>

      <Footer />

      {/* Housing Resources Modal Popup */}
      <div
        className="modal modal-fullscreen-xl fadeInDown animated tab-modal"
        id="RentersInsurance"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <RentersInsuranceModal />
      </div>
      <div
        className="modal modal-fullscreen-xl fadeInDown animated tab-modal"
        id="RentalApplicationProcess"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <RentalApplicationProcessModal />
      </div>
      <div
        className="modal modal-fullscreen-xl fadeInDown animated tab-modal"
        id="TenantRights"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <TenantRightsPopUP />
      </div>
      <div
        className="modal modal-fullscreen-xl fadeInDown animated tab-modal"
        id="RentalScams"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <RentalScamsPopUp />
      </div>

      {/* Resources  modal pop up */}
      <div
        className="modal modal-fullscreen-xl fadeInDown animated tab-modal"
        id="NonTAModalMovingTips"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <NonTAMovingTips />
      </div>

      <div
        className="modal modal-fullscreen-xl fadeInDown animated tab-modal"
        id="NonTAPackingRecommendations"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <NonTAPackingRecommendations />
      </div>

      <div
        className="modal modal-fullscreen-xl fadeInDown animated tab-modal"
        id="myModalMovingTips"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <MovingTips />
      </div>

      <div
        className="modal modal-fullscreen-xl fadeInDown animated tab-modal"
        id="PackingRecommendations"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <PackingRecommendationsPopUpTips />
      </div>

      <div
        className="modal modal-fullscreen-xl fadeInDown animated tab-modal"
        id="myModalSocialSecurity"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <SocialPopUp />
      </div>

      <div
        className="modal modal-fullscreen-xl fadeInDown animated tab-modal"
        id="myModalDriversLicense"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <DriverLicensePopUp />
      </div>

      <div
        className="modal modal-fullscreen-xl fadeInDown animated tab-modal"
        id="myModalRegisteringEmbassy"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <RegisterPopUp />
      </div>

      <div
        className="modal modal-fullscreen-xl fadeInDown animated tab-modal"
        id="myModalForwardingMail"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <ForwardingPopUp />
      </div>

      <div
        className="modal modal-fullscreen-xl fadeInDown animated tab-modal"
        id="myModalsettingUpBanking"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <BankingPopUp />
      </div>

      <div
        className="modal modal-fullscreen-xl fadeInDown animated tab-modal"
        id="myModalsettingUpUtilities"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <UtilitiesPopUp />
      </div>
    </div>
  );
}

export default ResourceLanding;
