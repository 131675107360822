import { useState, useEffect, useRef } from "react";
import Footer from "../../../common/footer/Footer";
import {
  faEdit,
  faCalendarAlt,
  faTrash,
  faClock,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import CompletedTasksModal from "./PopupModals/completedModal";
import * as $ from "jquery";
import EditModal from "./PopupModals/EditModal";
import ThirdPartyApiService from "../../../../services/thirdPartyService";
import { Select, Radio, Tooltip, Button, Spin } from "antd";
import ApiServices from "../../../../services/apiServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import OwlCarousel from "react-owl-carousel";
import AppController from "../../../controllers/appController";
import { confirmAlert } from "react-confirm-alert";

const CheckList = () => {
  const CheckListowlRef = useRef(null);
  const [state, setState] = useState({
    isTemporary: false,
    email: "ashok.kandipati@suiteamerica.com",
    subject: "",
    description: "",
    succesMsg: "",
    contactError: "",
    FromEmail: "",
    firstname: "",
    lastname: "",
    phoneNumber: "",
    countrycode: "",
    sessionObject: true,
    checklistdescription: "",
    checklistTypes: ["Pre-Move", "Post-Move"],
    ChecklistTypeId: "Pre-Move",
    category: "",
    reminder: false,
    editDate: null,
    deadlinedate: null,
    loader: false,
    reminderState: "",
    TaskStatusSuccess: false,
    changePasswordErrorStatus: false,
    errorMessage: "",
    checklist: [],
    checklistOriginal: [],
    transfereechecklistuid: "",
    succesMsg: "Added Task Successfully",
    completedTasks: [],
    PostMoveTasks: [],
    PreMoveTasks: [],
    userUniqueId: "",
    status: "",
    completeddate: "",
    OtherCategory: [],
    TravelCategory: [],
    HouseholdCategory: [],
    PackingCategory: [],
    oldHomeCategory: [],
    NewHomeCategory: [],
    PetsCategory: [],
    tabItems: "",
    startPosition: 0,
    selectedType: "All",
    selectedCategoty: "",
    categoryTypes: [
      "Old Home",
      "New Home",
      "Pets",
      "Household Goods",
      "Travel",
      "Other",
      "Medical",
      "Records & Memberships",
      "Home Finding",
      "Driving",
      "Packing & Moving",
      "Financial Planning",
      "Government",
      "School",
    ],
    pageLoader: false,
    categoryTypesList: [
      { name: "Old Home", icon: "fa-regular fa-house" },
      { name: "New Home", icon: "fa-regular fa-house" },
      { name: "Pets", icon: "fa-regular fa-paw" },
      { name: "Household Goods", icon: "fa-regular fa-bag-shopping" },
      { name: "Travel", icon: "fa-regular fa-bus-simple" },
      { name: "Other", icon: "fa-regular fa-square-list" },
      { name: "Medical", icon: "fa-regular fa-suitcase-medical" },
      { name: "Records & Memberships", icon: "fa-regular fa-thumbs-up" },
      { name: "Home Finding", icon: "fa-regular fa-house" },
      { name: "Driving", icon: "fa-regular fa-car" },
      { name: "Packing & Moving", icon: "fa-regular fa-box-open" },
      {
        name: "Financial Planning",
        icon: "fa-solid fa-chart-mixed-up-circle-dollar",
      },
      { name: "Government", icon: "fa-regular fa-school-flag" },
      { name: "School", icon: "fa-regular fa-graduation-cap" },
    ],
    isCompTask: false,
    remindertype: "",
    reminderby: "",
    clickedCategory: "",
  });
  let listOriginal = [];
  const dataFilter = (tasks, filters) => {
    return tasks.filter((task) => {
      const { status, checklistType, category } = filters;

      return (
        (status === undefined || task.status === status) &&
        (checklistType === undefined ||
          checklistType.includes(task.checklisttype)) &&
        (category === undefined || task.category === category)
      );
    });
  };
  useEffect(() => {
    async function fetchData() {
      let getSessionData = await ThirdPartyApiService.getLoginInfo({});
      await setState((prevState) => ({
        ...prevState,
        userUniqueId: getSessionData.userUniqueId,
        isTemporary: getSessionData.istemporary,
      }));
      await AppController.logData(
        "Checklist",
        "Checklist",
        location.pathname,
        getSessionData.userUniqueId,
        ""
      );
    }
    fetchData();
  }, []);
  const tab = () => {
    let tabItems =
      parseInt(screen.availWidth) <= 767
        ? 4
        : parseInt(screen.availWidth) <= 991
        ? 5
        : 6;
    setState((prevState) => ({
      ...prevState,
      tabItems: tabItems,
    }));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetch = () => {
      getTasks();
      tab();
    };
    fetch();
  }, []);
  const updateCarouselPosition = (object) => {
    if (object.item.index != state.startPosition) {
      setState((prevState) => ({
        ...prevState,
        startPosition: object.item.index,
      }));
    }
  };

  const getTasks = async () => {
    let getSessionData = await ThirdPartyApiService.getLoginInfo({});
    let taskObject = {
      transfereeuid: getSessionData.userUniqueId,
    };
    let getTasksResult = await ApiServices.GetTransfereeCheckList(taskObject);
    console.log(getTasksResult, "getTasksResult");
    let cat = [];
    getTasksResult.message.checklist.forEach(function (item) {
      Object.keys(item).forEach(function (key) {
        if (cat.indexOf(item["category"]) < 0) {
          cat.push(item["category"]);
        }
      });
    });
    // console.log(cat, "cat");
    getTasksResult.message.checklist
      .sort(function (a, b) {
        var dateA = new Date(a.deadlinedate),
          dateB = new Date(b.deadlinedate);
        return dateA - dateB;
      })
      .reverse();
    let completedTasks = dataFilter(getTasksResult.message.checklist, {
      status: "completed",
    });

    let PreMoveTasks = dataFilter(getTasksResult.message.checklist, {
      status: "incomplete",
      checklistType: "Pre-Move",
    });

    let PostMoveTasks = dataFilter(getTasksResult.message.checklist, {
      status: "incomplete",
      checklistType: "Post-Move",
    });

    let checklists = dataFilter(getTasksResult.message.checklist, {
      status: undefined,
      checklistType: undefined,
    });

    var statusOrder = ["Pre-Move", "PreMove", "PostMove", "Post-Move"];
    checklists.sort(
      (a, b) =>
        statusOrder.indexOf(a.checklisttype) -
        statusOrder.indexOf(b.checklisttype)
    );
    listOriginal = getTasksResult.message.checklist;
    await setState((prevState) => ({
      ...prevState,
      checklist: checklists,
      completedTasks: completedTasks,
      PreMoveTasks: PreMoveTasks,
      PostMoveTasks: PostMoveTasks,
      checklistOriginal: getTasksResult.message.checklist,
    }));
  };

  const Delete = async (row) => {
    confirmAlert({
      title: "",
      message: "Are you sure you want to delete this task?",
      buttons: [
        await {
          label: "Yes",
          onClick: () => {
            DeleteItem(row);
          },
        },
        await {
          label: "No",
          onClick: () => "",
        },
      ],
    });
  };
  const DeleteItem = async (e) => {
    let taskObject = {
      transfereechecklistuid: e.transfereechecklistuid,
      transfereeuid: e.transfereeuid,
      checklistdescription: e.checklistdescription,
      checklisttype: e.checklisttype,
      category: e.category,
      reminder: e.reminder,
      deadlinedate: e.deadlinedate,
      completeddate: e.completeddate,
      status: e.status,
      isdelete: true,
    };
    let addTaskResult = await ApiServices.ManagerTransfereeCheckList(
      taskObject
    );
    if (addTaskResult.error == 0) {
      if (state.selectedCategoty != "") {
        await setState((prevState) => ({ ...prevState, isCompTask: true }));
        await getTasks();
        await ChangeCategory(state.selectedCategoty);
      } else {
        await getTasks();
        await changeType(state.selectedType);
      }
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpent, setIsModalOpent] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);

  const onSelectEditTask = async (row) => {
    // console.log(row, "haii");
    //setIsModalOpen(true);
    $(function () {
      $("document").ready(async function () {
        await setState((prevState) => ({
          ...prevState,
          transfereechecklistuid: row.transfereechecklistuid,
          checklistdescription: row.checklistdescription,
          ChecklistTypeId: row.checklisttype,
          category: row.category,
          reminderState: row.reminder,
          reminder: row.reminder == null ? false : row.reminder,
          deadlinedate: row.deadlinedate,
          editDate:
            row.deadlinedate == null ? null : new Date(row.deadlinedate),
          TaskStatusSuccess: false,
          status: row.status,
          completeddate: row.completeddate,
          isCompTask: false,
          reminderby:
            row.reminderon == null || row.reminderon.length < 4
              ? null
              : new Date(row.reminderon),
          remindertype: row.remindertype == null ? "" : row.remindertype,
        }));
        $("#add-check-list").modal("show");
      });
    });
  };
  const onSelectAddTask = async () => {
    $(function () {
      $("document").ready(async function () {
        await setState((prevState) => ({
          ...prevState,
          transfereechecklistuid: "",
          checklistdescription: "",
          ChecklistTypeId: "Pre-Move",
          category: "",
          reminderState: "",
          deadlinedate: null,
          editDate: null,
          reminder: false,
          TaskStatusSuccess: false,
          reminderby: "",
          remindertype: "",
        }));
        await setState((prevState) => ({
          ...prevState,
          reminderby: "",
          remindertype: "",
        }));
        //setIsModalOpen(true);
        $("#add-check-list").modal("show");
      });
    });
  };
  const CompletedModal = () => {
    $(function () {
      $("document").ready(async function () {
        $("#completed-check-list").modal("show");
      });
    });
  };
  const onClickCheckCompleted = async (e) => {
    let taskObject = {
      transfereechecklistuid: e.transfereechecklistuid,
      transfereeuid: e.transfereeuid,
      checklistdescription: e.checklistdescription,
      checklisttype: e.checklisttype,
      category: e.category,
      reminder: e.reminder,
      deadlinedate: e.deadlinedate,
      completeddate: "",
      status: "",
      isdelete: false,
    };
    taskObject.status = "completed";
    taskObject.completeddate = moment().format("LLLL");
    let addTaskResult = await ApiServices.ManagerTransfereeCheckList(
      taskObject
    );
    if (addTaskResult.error == 0) {
      if (state.selectedCategoty != "") {
        await setState((prevState) => ({ ...prevState, isCompTask: true }));
        await getTasks();
        await ChangeCategory(state.selectedCategoty);
      } else {
        await getTasks();
        await changeType(state.selectedType);
      }
      let data = {
        transfereeuid: state.userUniqueId,
      };
      const GetTransfereeStatus = await ApiServices.GetTransfereeStatus(data);
      let udc = [];
      if (GetTransfereeStatus.error == 0) {
        udc = await _.filter(
          GetTransfereeStatus.message.noteslist,
          function (o) {
            return o.statusuid == "deb8d951-c80b-43df-b711-dad2c49baa03";
          }
        );
      }
    }
  };
  const onClickCheck = async (row) => {
    if (row.status != "completed") {
      confirmAlert({
        title: "",
        message: "Please confirm that this task is completed?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              onClickCheckCompleted(row);
            },
          },
          {
            label: "No",
            onClick: () => "",
          },
        ],
      });
    }
  };

  // const ChangeCategory = async (e) => {
  //   console.log(e, "changeCategory");
  //   await setState((prevState) => ({
  //     ...prevState,
  //     pageLoader: true,
  //     clickedCategory: e,
  //   }));
  //   if (e == state.selectedCategoty && state.isCompTask == false) {
  //     await setState((prevState) => ({ ...prevState, selectedCategoty: "" }));
  //     await changeType(state.selectedType);
  //   } else {
  //     await setState((prevState) => ({ ...prevState, selectedCategoty: e }));
  //     if (state.selectedType == "Pre-Move") {
  //       await getTasks();
  //       let PreMoveTasks = dataFilter(state.checklistOriginal, {
  //         checklistType: ["Pre-Move", "PreMove"],
  //         category: e,
  //       });
  //       await setState((prevState) => ({
  //         ...prevState,
  //         checklist: PreMoveTasks,
  //       }));
  //     }
  //     if (state.selectedType == "Post-Move") {
  //       await getTasks();
  //       let PostMoveTasks = dataFilter(state.checklistOriginal, {
  //         checklistType: ["Post-Move", "PostMove"],
  //         category: e,
  //       });
  //       await setState((prevState) => ({
  //         ...prevState,
  //         checklist: PostMoveTasks,
  //       }));
  //     }
  //     if (state.selectedType == "All") {
  //       await getTasks();
  //       let sample = state.checklistOriginal;
  //       let checklist = dataFilter(sample, { category: e });
  //       await setState((prevState) => ({ ...prevState, checklist: checklist }));
  //     }
  //   }
  //   await setState((prevState) => ({ ...prevState, pageLoader: false }));
  // };
  const ChangeCategory = async (e) => {
    // console.log(e, "changeCategory", state.selectedCategoty);

    await setState((prevState) => ({
      ...prevState,
      pageLoader: true,
      clickedCategory: e,
    }));

    if (e === state.selectedCategoty && !state.isCompTask) {
      await setState((prevState) => ({
        ...prevState,
        selectedCategory: "",
      }));
      await changeType(state.selectedType);
    } else {
      await setState((prevState) => ({
        ...prevState,
        selectedCategory: e,
      }));

      await getTasks();

      if (state.selectedType === "Pre-Move") {
        let PreMoveTasks = dataFilter(state.checklistOriginal, {
          checklistType: ["Pre-Move", "PreMove"],
          category: e,
        });
        await setState((prevState) => ({
          ...prevState,
          checklist: PreMoveTasks,
        }));
      } else if (state.selectedType === "Post-Move") {
        let PostMoveTasks = dataFilter(state.checklistOriginal, {
          checklistType: ["Post-Move", "PostMove"],
          category: e,
        });
        await setState((prevState) => ({
          ...prevState,
          checklist: PostMoveTasks,
        }));
      } else {
        let sample = listOriginal;
        let checklist = dataFilter(sample, { category: e });
        await setState((prevState) => ({ ...prevState, checklist: checklist }));
      }
    }

    await setState((prevState) => ({
      ...prevState,
      pageLoader: false,
    }));
  };

  const changeType = async (e) => {
    // console.log(e, "type you clicked");
    $(".owl-item .nav-item .nav-link").removeClass("active");
    await setState((prevState) => ({
      ...prevState,
      selectedCategoty: "",
      selectedType: "",
      pageLoader: true,
    }));
    await setState((prevState) => ({
      ...prevState,
      selectedType: e,
      isCompTask: false,
    }));
    if (e == "Pre-Move") {
      await getTasks();

      let PreMoveTasks = dataFilter(state.checklistOriginal, {
        checklistType: ["Pre-Move", "PreMove"],
      });
      await setState((prevState) => ({
        ...prevState,
        checklist: PreMoveTasks,
        pageLoader: false,
      }));
    }
    if (e == "Post-Move") {
      await getTasks();

      let PostMoveTasks = dataFilter(state.checklistOriginal, {
        checklistType: ["Post-Move", "PostMove"],
      });
      await setState((prevState) => ({
        ...prevState,
        checklist: PostMoveTasks,
        pageLoader: false,
      }));
    }
    if (e == "All") {
      await getTasks();
      let checklists = dataFilter(state.checklistOriginal, {
        status: undefined,
        checklistType: undefined,
      });

      var statusOrder = ["Pre-Move", "PreMove", "PostMove", "Post-Move"];
      checklists.sort(
        (a, b) =>
          statusOrder.indexOf(a.checklisttype) -
          statusOrder.indexOf(b.checklisttype)
      );
      setState((prevState) => ({
        ...prevState,
        checklist: checklists,
        pageLoader: false,
      }));
    }
    // if (state.selectedCategoty != "") {
    //   await ChangeCategory(state.selectedCategoty);
    // }
    await setState((prevState) => ({ ...prevState, pageLoader: false }));
  };

  return (
    <div className="outer-container">
      <div className="check-list-page">
        <div className="container">
          {state.pageLoader == true && <div className="loading">Loading</div>}
          <div className="nav-view position-relative">
            <ul className="nav nav-tabs mt-0 mb-4" role="tablist">
              <li className="nav-item">
                <a
                  className={
                    state.selectedType == "All"
                      ? "nav-link active"
                      : "nav-link fw-bold"
                  }
                  data-toggle="tab"
                  onClick={() => changeType("All")}
                >
                  All
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={
                    state.selectedType == "Pre-Move"
                      ? "nav-link active"
                      : "nav-link fw-bold"
                  }
                  data-toggle="tab"
                  onClick={() => changeType("Pre-Move")}
                >
                  Pre-Move
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={
                    state.selectedType == "Post-Move"
                      ? "nav-link active"
                      : "nav-link fw-bold"
                  }
                  data-toggle="tab"
                  onClick={() => changeType("Post-Move")}
                >
                  Post-Move
                </a>
              </li>
            </ul>
            <div className="top-task-btns">
              <a
                href="javascript:void(0)"
                className="task"
                onClick={onSelectAddTask}
              >
                Add Task{" "}
              </a>
              <a
                href="javascript:void(0)"
                className="task"
                onClick={() => CompletedModal()}
              >
                Completed Tasks
              </a>
              <CompletedTasksModal
                isOpen={isModalOpent}
                onClose={() => setIsModalOpent(false)}
                completedTasks={state.completedTasks}
                onClickCheck={() => onClickCheck()}
                onSelectEditTask={() => onSelectEditTask()}
                onDelete={() => Delete()}
              />
            </div>
            <ul className="nav nav-tabs scroll-ul mt-2" role="tablist">
              <OwlCarousel
                className="owl-theme"
                ref={CheckListowlRef}
                nav
                dots={false}
                margin={10}
                autoWidth={true}
                responsiveClass={true}
                responsive={{
                  0: {
                    items: 1,
                  },
                  600: {
                    items: 2,
                  },
                  1000: {
                    items: 3,
                  },
                }}
                items={state.tabItems}
                startPosition={state.startPosition}
                onDragged={(object) => updateCarouselPosition(object)}
                onTranslated={(object) => updateCarouselPosition(object)}
              >
                {_.sortBy(
                  state.categoryTypesList.filter(
                    (row) =>
                      (row.name !== "Home Finding" &&
                        row.name != "New Home" &&
                        row.name != "Old Home" &&
                        row.name != "School") ||
                      !state.isTemporary
                  ),
                  [
                    function (o) {
                      return o.name;
                    },
                  ]
                ).map((row, index) => {
                  return (
                    <li className="nav-item" key={index}>
                      <a
                        className={
                          state.clickedCategory == row.name
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                        id={row.name.replace(/[^a-zA-Z]/g, "")}
                        onClick={() => ChangeCategory(row.name)}
                      >
                        <i className={row.icon}></i>

                        {row.name.charAt(0).toUpperCase() +
                          row.name.slice(1).replace(/_/g, " ")}
                      </a>
                    </li>
                  );
                })}
              </OwlCarousel>
            </ul>
          </div>
          <div className="main-view">
            <div className="tabs-check">
              <div className="tab-content" style={{ padding: "10px" }}>
                {state.checklist.length > 0 ? (
                  <div id="allcheck" className="tab-pane active">
                    {state.checklist
                      .sort((a, b) => {
                        if (a.checklisttype === b.checklisttype) {
                          return a.checklistdescription.localeCompare(
                            b.checklistdescription
                          );
                        } else {
                          return a.checklisttype === "Pre-Move" ||
                            a.checklisttype === "PreMove"
                            ? -1
                            : 1;
                        }
                      })
                      .map((row, index) => {
                        return (
                          <div className="top_label-div" key={index}>
                            <label className="checklist">
                              {row.checklistdescription
                                .charAt(0)
                                .toUpperCase() +
                                row.checklistdescription.slice(1)}
                              <input
                                type="checkbox"
                                id={index}
                                onChange={() => onClickCheck(row)}
                                checked={
                                  row.status == "completed" ? true : false
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                            <span>
                              {" "}
                              <a>
                                {state.selectedType == "All" ? (
                                  <span
                                    className={
                                      row.checklisttype == "Pre-Move" ||
                                      row.checklisttype == "PreMove"
                                        ? "label label-primary"
                                        : "label label-success"
                                    }
                                  >
                                    {row.checklisttype == "Pre-Move" ||
                                    row.checklisttype == "PreMove"
                                      ? "Pre-Move"
                                      : "Post-Move"}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </a>
                              {row.status != "completed" && (
                                <a>
                                  <i
                                    className="fa-regular fa-pen-to-square"
                                    onClick={() => onSelectEditTask(row)}
                                  ></i>
                                  {/* <FontAwesomeIcon
                                  icon={faPenToSquare}
                                  onClick={() => onSelectEditTask(row)}
                                /> */}
                                  {/* <FontAwesomeIcon icon={faEdit} />{" "} */}
                                </a>
                              )}
                              {row.status != "completed" ? (
                                <a>
                                  <i
                                    className="fa-regular fa-trash-can"
                                    onClick={() => Delete(row)}
                                  ></i>
                                  {/* <FontAwesomeIcon
                                  icon={faTrash}
                                  onClick={() => Delete(row)}
                                />{" "} */}
                                </a>
                              ) : (
                                <a>
                                  <Tooltip
                                    title={
                                      "Completed on " +
                                      moment(row.completeddate).format("LLLL")
                                    }
                                  >
                                    <span>
                                      <i className="fa-regular fa-clock"></i>
                                      {/* <FontAwesomeIcon icon={faClock} /> */}
                                    </span>
                                  </Tooltip>{" "}
                                </a>
                              )}
                            </span>
                          </div>
                        );
                      })}
                  </div>
                ) : (
                  <label className="checklist">
                    There are no available checklist at this time!
                  </label>
                )}
              </div>
            </div>
            <div className="progress-view">
              <div className="image">
                <p>checklist progress</p>
                <img src="../assets/images/progress.svg" alt="" />
                <div className="progress">
                  <div
                    className="progress-bar"
                    style={{
                      width:
                        (state.completedTasks.length /
                          state.checklistOriginal.length) *
                          100 +
                        "%",
                    }}
                  ></div>
                </div>
                <h6>
                  {state.completedTasks.length} Out of{" "}
                  {state.checklistOriginal.length} completed
                </h6>
              </div>
            </div>
          </div>
        </div>
        <EditModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          state={state}
          setState={setState}
          getTasks={getTasks}
          changeType={changeType}
          ChangeCategory={ChangeCategory}
        />
      </div>
      <Footer />
    </div>
  );
};
export default CheckList;
